import moment from 'moment-timezone'
import { TZ_MSK, timeZonesList } from './constants'
import { IEvent } from '@models/ICalendar'

export const calendarUtil = {
  convertToUTC: (datetime: string | number) => {
    return moment(datetime).utc()
  },

  convertToMSK: (datetime: string | number) => {
    return moment(datetime).tz(TZ_MSK)
  },

  getAllEvents: (events: IEvent[], timeZone: string, isBefore = true) => {
    moment.tz.setDefault('UTC')
    let allEvents = []

    events.forEach(item => {
      let startDate = moment(item.rrule.dtstart)
      let eventDates = [startDate.format()]

      for (let i = 1; i < item.rrule.count; i++) {
        startDate = startDate.add(7, 'days')
        eventDates.push(startDate.format())
      }

      const validEventDates = eventDates.filter(dt => !item.exdate?.includes(moment(dt).format()))

      validEventDates.forEach(dtstart => {
        allEvents.push({
          ...item,
          rrule: {
            ...item.rrule,
            dtstart: moment(dtstart)
              .tz(timeZone || TZ_MSK)
              .format(),
          },
        })
      })
    })

    if (!isBefore) {
      const currentMoment = moment().subtract(1, 'hour')
      allEvents = allEvents.filter(event => {
        return currentMoment.isBefore(moment(event.rrule.dtstart))
      })
    }

    moment.tz.setDefault(timeZone)

    return allEvents.sort(
      (a, b) => new Date(a.rrule.dtstart).getTime() - new Date(b.rrule.dtstart).getTime()
    )
  },
  getNearestEvent: (events: IEvent[], timeZone: string, before: boolean = false) => {
    const allEvents = calendarUtil.getAllEvents(events, timeZone, before)
    let currentDate = moment
      .tz(timeZone)
      .format()
      .substring(0, 16)

    let nearestEvent

    if (before) {
      nearestEvent = allEvents
        .filter(event => {
          return new Date(currentDate).getTime() - new Date(event.rrule.dtstart).getTime() > 0
        })
        .sort(
          (a, b) => new Date(b.rrule.dtstart).getTime() - new Date(a.rrule.dtstart).getTime()
        )[0]
    } else {
      nearestEvent = allEvents
        .filter(event => {
          return new Date(currentDate).getTime() - new Date(event.rrule.dtstart).getTime() < 0
        })
        .sort(
          (a, b) => new Date(a.rrule.dtstart).getTime() - new Date(b.rrule.dtstart).getTime()
        )[0]
    }

    return nearestEvent
  },

  convertEventsToTimezone: (timeZone: string, events: IEvent[]) => {
    const eventsTz = events.map(item => {
      const dtstart = moment(item.rrule.dtstart)
        .tz(timeZone)
        .format()
      let byweekDay = [
        moment(dtstart)
          .locale('en')
          .format('dd'),
      ]
      return {
        ...item,
        rrule: {
          ...item.rrule,
          byweekday: byweekDay,
          dtstart,
        },
      }
    })

    return eventsTz
  },

  getAvailableTimezones: () => {
    return timeZonesList.map(timezone => ({
      value: timezone,
      label: timezone + ' (' + moment.tz(timezone).format('Z') + ')',
    }))
  },
  getTimezoneAbbr: (timeZone: string) => {
    return isNaN(Number(moment.tz(timeZone).zoneAbbr()))
      ? moment.tz(timeZone).zoneAbbr()
      : new Date().toLocaleTimeString('en-us', { timeZoneName: 'short', timeZone }).split(' ')[2]
  },
}
