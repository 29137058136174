import React, { useMemo, useState } from 'react'
import * as Styled from './course.styles'

import { declOfNum, isBlackFriday } from '@utils/helperst'
import {
  CURRENCIES,
  CURRRENCY_SYMBOL,
  expiredDays,
  FIREBASE_CONFIG,
  IS_DEV_MODE,
  PACKAGE_TYPE,
} from '@utils/constants'
import { authRef } from '@configs/firebase'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { RootState } from '@store/index'
import { useSelector } from 'react-redux'
import { IStudentProfile } from '@models/IProfile'
import MainModal from '@components/Modal'
import OfferModal from './OfferModal'
import LifeTimePackage from '../Icons/LifeTimePackage'

interface Props {
  lessons: number
  price: number
  type: string
  promoCode: string
  oldLessonPrice: number
  currency: string
}

const Course = ({ lessons, price, type, promoCode, oldLessonPrice, currency }: Props) => {
  const history = useHistory()
  const student = useSelector((state: RootState) => state.profile.profile as IStudentProfile)
  const [isOpen, setIsOpen] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const isPopular = type === PACKAGE_TYPE.RUSSIAN && lessons === 24

  const withNativeLessons = {
    16: 1,
    32: 2,
  }

  const withNativeLessonsCount = type === PACKAGE_TYPE.RUSSIAN && withNativeLessons[lessons]

  const isNoPayment = useMemo(() => {
    return !student?.payments?.find(payment => payment.amount > 0)
  }, [student?.payments])

  const noLessonsWithinTwoMonths = useMemo(() => {
    const TWO_MONTHS_AGO = new Date()
    TWO_MONTHS_AGO.setDate(TWO_MONTHS_AGO.getDate() - 60)

    const lastPayment = student?.payments
      ?.filter(payment => payment.package === -1 && payment.date)
      .sort((a, b) => b.date.toDate() - a.date.toDate())[0]

    if (!lastPayment) return true

    const lastPaymentDate = lastPayment.date.toDate()

    return lastPaymentDate <= TWO_MONTHS_AGO
  }, [student?.payments])

  const goToPay = () => {
    const email = authRef.currentUser.email

    const test = IS_DEV_MODE ? `&test=${IS_DEV_MODE}` : ''

    if (currency === CURRENCIES.RUB) {
      const link = `${
        FIREBASE_CONFIG.paymentFnLink
      }?email=${email}&type=${type}&lessons=${lessons}&promoCode=${
        promoCode.length > 0 ? promoCode : ''
      }${test}`
      window.location.href = link
    } else if (currency === CURRENCIES.EUR && student.isStripe) {
      history.push(
        `/checkout/${lessons}/${type}/${currency.toLowerCase()}/0/false/${
          authRef.currentUser.uid
        }/${promoCode.length > 0 ? promoCode : 'false'}`
      )
    } else if (currency === CURRENCIES.KZT) {
      const link = `${
        FIREBASE_CONFIG.payOneFnLink
      }?email=${email}&type=${type}&lessons=${lessons}&currency=${currency.toLowerCase()}&promoCode=${
        promoCode.length > 0 ? promoCode : ''
      }${test}`
      window.location.href = link
    } else {
      const link = `${
        FIREBASE_CONFIG.paySelectionFnLink
      }?email=${email}&type=${type}&lessons=${lessons}&currency=${currency.toLowerCase()}&promoCode=${
        promoCode.length > 0 ? promoCode : ''
      }${test}`
      window.location.href = link
    }
  }
  const lessonPrice = price / lessons
  const { t } = useTranslation()
  const decl = (n: number) => declOfNum(n, [t('Class1'), t('Class2'), t('Class3')])

  const oldPrice = oldLessonPrice * lessons
  const economy = oldPrice - price

  return (
    <Styled.Course
      isPopular={isPopular}
      isBlackFriday={
        type === PACKAGE_TYPE.HISPANIC && isBlackFriday && (isNoPayment || noLessonsWithinTwoMonths)
      }
    >
      {isPopular && <Styled.Badge>{t('MostPopular')}</Styled.Badge>}
      {!isPopular && type !== PACKAGE_TYPE.GROUP && (
        <Styled.CustomTooltip
          placement="top"
          title={t('LifeTimePackage', { lessons, lifeTime: expiredDays[lessons] })}
          onOpen={() => setIsHovered(true)}
          onClose={() => setIsHovered(false)}
        >
          <Styled.IconContainer>
            <LifeTimePackage isHovered={isHovered} />
          </Styled.IconContainer>
        </Styled.CustomTooltip>
      )}

      <Styled.NumberLessons isPopular={isPopular}>{lessons}</Styled.NumberLessons>
      <Styled.CourseClass isPopular={isPopular}>{decl(lessons)}</Styled.CourseClass>
      {type === 'group' && <Styled.GroupDesc>{t('GropuCourse.Desc')}</Styled.GroupDesc>}
      <Styled.LessonPrice>
        <Styled.Price isPopular={isPopular}>
          {Number(lessonPrice.toFixed(1)).toLocaleString('ru-RU') +
            ' ' +
            CURRRENCY_SYMBOL[currency]}
        </Styled.Price>
        / {t('Lesson1')}
      </Styled.LessonPrice>
      {oldPrice > price ? (
        <Styled.LastPrice>
          {oldPrice.toLocaleString('ru-RU') + ' ' + CURRRENCY_SYMBOL[currency]}
        </Styled.LastPrice>
      ) : (
        <Styled.LastPrice>
          <br />
        </Styled.LastPrice>
      )}

      <Styled.BuyButton
        isPopular={isPopular}
        isBlackFriday={
          type === PACKAGE_TYPE.HISPANIC &&
          isBlackFriday &&
          (isNoPayment || noLessonsWithinTwoMonths)
        }
        onClick={() => setIsOpen(true)}
      >
        <Styled.ButtonText>
          {price.toLocaleString() + ' ' + CURRRENCY_SYMBOL[currency]}
        </Styled.ButtonText>
      </Styled.BuyButton>
      {type === 'group' ? (
        <Styled.Economy>{t('GropuCourse.Desc2')}</Styled.Economy>
      ) : (
        <Styled.Economy>
          {isPopular ? (
            t('Works2months')
          ) : economy > 0 ? (
            `${t('Economy')} ${economy.toLocaleString('ru-RU')} ${CURRRENCY_SYMBOL[currency]}`
          ) : (
            <>
              <br />
            </>
          )}
        </Styled.Economy>
      )}

      <MainModal
        open={isOpen}
        children={() => <OfferModal onCancel={() => setIsOpen(false)} onConfirm={goToPay} />}
        handleClose={() => setIsOpen(false)}
        maxWidth="620px"
      />
    </Styled.Course>
  )
}

export default Course
