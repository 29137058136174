import styled from 'styled-components'
import { theme } from '@styles/Theme'

export const BalanceHistory = styled.table`
  display: block;
  padding: 30px 40px;
  th,
  td {
    flex-basis: 85%;
    flex-grow: 1;
    display: block;
    text-align: left;
    padding-right: 50px;
  }
`

export const BalanceHead = styled.thead`
  position: relative;
  display: block;
  width: 100%;
  height: 40px;
  overflow-y: scroll;
  color: rgba(144, 164, 189, 1);
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
  }
`

export const BalanceBody = styled.tbody`
  display: block;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  max-height: 400px;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: rgba(65, 71, 82, 1);
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(144, 164, 189, 0.5);
  }
`

export const BalanceColumn = styled.tr<{ isGreen?: boolean }>`
  width: 100%;
  display: flex;

  td {
    padding-top: 30px;
  }

  > td:nth-child(-n + 3) {
    color: ${props => props.isGreen && 'rgba(33, 150, 83, 1)'};
  }

  > td:nth-last-child(-n + 2) {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    flex-basis: 35%;
  }

  > th:nth-last-child(-n + 2) {
    text-align: center;
    flex-basis: 35%;
  }

  > td:nth-child(2),
  th:nth-child(2) {
    flex-basis: 190%;
  }
`

export const DateColumn = styled.span<{ isGreen?: boolean }>`
  color: ${props => (props.isGreen && 'rgba(33, 150, 83, 1)') || 'gray'};
  font-weight: bold;
`
export const TimeColumn = styled.span<{ isGreen?: boolean }>`
  color: ${props => (props.isGreen && 'rgba(33, 150, 83, 1)') || 'gray'};
  margin-left: 10px;
  font-size: 16px;
`

export const ActivePackage = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
  padding: 30px 40px 0 40px;

  & span {
    color: #4d6084;
    font-size: 18px;
    font-weight: 700;
    line-height: 24.51px;
  }
`

export const ChoosenOption = styled.div`
  color: #7e92ac;
  border: 1px solid #c2cfe0;
  border-radius: 20px;
  padding: 6px 14px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  height: 30px;
  > img {
    margin-left: 12px;
  }
  position: relative;
`
export const Options = styled.div`
  position: absolute;
  transform: translateY(100%);
  bottom: -10px;
  left: 0;
  min-width: 100%;
  border: 1px solid #c2cfe0;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  background-color: #fff;
  padding: 20px 10px;
  opacity: 1;
  z-index: 2;
`
export const OptionItem = styled.button`
  color: #414752;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  letter-spacing: 0.01em;
  box-sizing: border-box;
`

export const ExpiredBlock = styled.div`
  display: flex;
  flex: 1;
  justify-content: end;
  margin-right: 80px;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 24.51px;
  color: rgba(77, 96, 132, 1);
`

export const Wrapper = styled.div`
  background: rgba(225, 236, 255, 0.5);
  border-radius: 20px;
`
