import React from 'react'
import { ThemeProvider } from 'styled-components'

export const theme = {
  colors: {
    orange: '#FF852D',
    gray: '#C4C4C4',
    white: '#fff',
    red: '#FF0000',
    subGray: '#9C9C9C',
    yellow: '#FF922D',
    purple_blue: '#2B4659',
    zinnwaldite: '#EBCAB7',
    sepia_craiola: '#9C6A4D',
    black: '#000000',
    ocean_blue: '#192A3E',
    blue: '#336A93',
    gainsborough: '#E5E5E5',
    textDark: '#414752',
    bullColour: '#566A8F',
    headlineDark: '#1B3B77',
    bgGray: '#90A4BD',
    bgBlue: '#1556CF',
    bgBlueLight: '#9ABDFC',
    bgBlueLight2: '#E1ECFF',
    bgOrangeLight: '#FFE3C9',
    bgBlueGradient: 'linear-gradient(90deg, #9AD9FC 0%, #4284FF 75%)',
    btnHoverOrange: '#FFB800',
    activePlatform: '#336A9',
    bgLightSide1: 'RGBA(194, 207, 224, 0.5)',
    btnColor: '#FF852D',
    btnBg: 'linear-gradient(270.29deg, #FF922D 18.37%, #FFB800 99.74%)',
    scrollTrack: 'RGBA(194, 207, 224, 0.2)',
    scrollThumb: '#c2cfe0',
    green2: '#27AE60',

    inputBG: '#fff',
    inputErr: '#FF0000',
    inputFilled: '#FF852D',
    input: '#C4C4C4',

    underLine: '#2B4659',
    underLineCaret: '#FF852D',
    bgNav: '#4D6084',
    bgHeader: '#ffffff',
    bgBtn: '#FFF5EE',

    // link
    linkColor: '#9C9C9C',
    bgDarkSide: '#1556CF',
    bgLightSide: '#C2CFE0',
    bgDashboard: '#F3F5F9',
    info: '#C2CFE0',
    classBg: '#FFFFFF',
    button: '#ff922d',
    buttonHover: '#FFB800',
    hwMainBoxShadow: 'rgba(0, 0, 0, 0.08)',
    hwActiveBoxShadow: 'rgba(255, 146, 45, 0.5)',
    hmCheckedStatus: '#27ae60',
    hmNotReadyStatus: '#7E92AC',
    hmInProgress: '#7E92AC',
    hmStartStatus: '#4284FF',
    hmSentStatus: '#FFB800',
    hmStatusText: '#F3F5F9',
    hmCheckAIStatus: '#27AE60',
    greyMiddle: '#7E92AC',
    blackFriday: '#1C1C1C',
  },
  borderRadius: { mainDashBoard: '40px' },
  border: { gray: '1px solid #C2CFE0' },
  textColors: {
    dark: '#3D4667',
    light: '#FFFFFF',
    active: '#4284FF',
    menu: '#90A4BD',
    translation: '#90A4BD',
    pageHeader: '#1B3B77',
    mainContent: '#414752',
    additionalText: '#F3F5F9',
    footerSocial: '#4284FF',
    emptyPageTitle: '#1556CF',
    greyMiddle: '#7E92AC',
    black: '#192A3E',
    blueLightAccent: '#4284FF',
  },
  defaultFont: 'Open Sans',
  fonts: ['Open Sans', 'Roboto'],
  fontSizes: {
    small: '12px',
    medium: '14px',
    large: '18px',
  },
  profile: {
    smallImageSize: 60,
    largeImageSize: 100,
    imageBorderRadius: '50%',
  },
  menuIconSize: '22px',
  leftSidePadding: '30px',
}

const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>

export default Theme
