import { Dispatch } from 'redux'
import queryString from 'query-string'
import { toast } from 'react-toastify'
import app from 'firebase/app'
import i18next from 'i18next'

import {
  usersRef,
  authRef,
  classroomsRef,
  libraryRef,
  studentsRef,
  storageRef,
  managersRef,
  paymentsRef,
  configRef,
  salesManagersRef,
  firebaseTimestamp,
} from '@configs/firebase'

import {
  CURRRENCY_SYMBOL,
  FIREBASE_CONFIG,
  LEVELS,
  PACKAGE_TYPE,
  PROJECT_FILES_TYPES,
  REGISTER_CODES,
  ROLES,
  TZ_MSK,
} from '@utils/constants'

import { getAvatarNameFromUrl } from '@utils/helperst'

import { RootState } from '@store'

import {
  setProfileAction,
  updateBusinessHoursProfileAction,
  setProfileTeacherAction,
  setProfileFilesAction,
  deleteProfileFileAction,
  setStudentPromoCode,
  updateProfileFileAction,
  setIsStudent,
} from '@store/profile/actions'
import { ProfileActionsTypes } from '@store/profile/types'
import { AuthActionsTypes } from '@store/auth/types'
import { userLoginAction, userLogoutAction } from '@store/auth/actions'
import { setListLoaded } from '@store/words/actions'

import {
  IProfile,
  IBusinessHours,
  ILibraryFile,
  IStudentProfile,
  IPayment,
  INotes,
  VoxData,
} from '@models'

import { getWordsAndSave } from './vocabulary'
import { fetchStudentPackages } from './packagesAPI'
import { changeInterestsKeys, changeGoalsKeys } from './personalAPI'
import { logger } from '@utils/ConsoleLogger'
import { sendDataToStudentTelegram } from './webhookAPI'

const { docs, image, audio, video } = PROJECT_FILES_TYPES

var config = {
  apiKey: FIREBASE_CONFIG.apiKey,
  authDomain: FIREBASE_CONFIG.authDomain,
  databaseURL: FIREBASE_CONFIG.databaseURL,
}
var secondaryApp = app.initializeApp(config, 'Secondary')

export const getProfile = (uid: string) => {
  return async (dispatch: Dispatch<ProfileActionsTypes>) => {
    let snapshot = await usersRef.doc(uid).get()
    let snapshotVoxData = await configRef.doc('voximplant').get()
    let voxData = snapshotVoxData.data() as VoxData

    let profileData = snapshot.data() as IProfile
    if (profileData === undefined) {
      throw Object({ code: REGISTER_CODES.other.teacher_not_found })
    }

    const timeZone = profileData.timeZone || TZ_MSK
    if (uid !== authRef.currentUser.uid) {
      dispatch(
        setProfileTeacherAction({
          ...profileData,
          id: snapshot.id,
          timeZone,
          voxData,
        })
      )
    } else {
      dispatch(
        setProfileAction({
          ...profileData,
          id: snapshot.id,
          timeZone,
          role: profileData.role || ROLES.TEACHER,
          voxData,
        })
      )
    }
    return {
      ...profileData,
      id: snapshot.id,
      timeZone,
      role: profileData.role || ROLES.TEACHER,
      voxData,
    }
  }
}

export const fetchStudentProfileByUid = async (uid: string): Promise<IStudentProfile> => {
  const snapshot = await studentsRef.doc(uid).get()
  let studentData = { ...snapshot.data() }
  const interests = changeInterestsKeys(studentData.interests || [])
  const goals = changeGoalsKeys(studentData.goals || [])

  return ({ ...studentData, interests, goals, role: ROLES.STUDENT } as unknown) as IStudentProfile
}

export const updateStudentComments = async (data: { uid: string; comments: string }) => {
  await studentsRef.doc(data.uid).update({ comments: data.comments })
}

export const fetchTeacherProfileByUid = async (uid: string): Promise<IProfile> => {
  const snapshot = await usersRef.doc(uid).get()
  return snapshot.data() as IProfile
}

export const updateStudentProfileCurrency = (id: string, currency: string) => async (
  dispatch: Dispatch<ProfileActionsTypes>,
  getState
) => {
  try {
    const cr: string = CURRRENCY_SYMBOL[currency]

    await studentsRef.doc(id).set({ cr }, { merge: true })
  } catch (e) {
    toast('Не удалось сохранить изменения', {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'student-message-failed',
    })
  }
}

export const fetchManagerProfileByUid = async (uid: string): Promise<IProfile> => {
  const snapshot = await managersRef.doc(uid).get()

  return snapshot.data() as IProfile
}
export const fetchSalesManagerProfileByUid = async (uid: string): Promise<IProfile> => {
  const snapshot = await salesManagersRef.doc(uid).get()

  return snapshot.data() as IProfile
}

export const fetchStudentPayments = async (uid: string): Promise<IPayment[]> => {
  const snapshot = await paymentsRef.doc(uid).get()
  let payments: IPayment[] = snapshot.exists
    ? snapshot.data() && Array.isArray(snapshot.data().data)
      ? snapshot.data().data
      : []
    : []

  payments = payments.sort(
    (a, b) =>
      new Date(b.date?.seconds ? b.date.toDate() : b.date).getTime() -
      new Date(a.date?.seconds ? a.date.toDate() : a.date).getTime()
  )
  return payments
}
export const getStudentProfile = (
  uid: string
): ((dispatch: Dispatch<ProfileActionsTypes>) => Promise<IStudentProfile>) => {
  return async (dispatch: Dispatch<ProfileActionsTypes>) => {
    let snapshotVoxData = await configRef.doc('voximplant').get()
    let voxData = snapshotVoxData.data() as VoxData
    const profileData = await fetchStudentProfileByUid(uid)
    if (!profileData) {
      throw Object({ code: REGISTER_CODES.other.student_not_found })
    } else if (profileData.student === undefined) {
      throw Object({ code: REGISTER_CODES.other.student_not_found })
    }
    const payments = await fetchStudentPayments(uid)
    let balanceCount = 0
    let passedLessons = 0

    let individualCount = 0
    let familyCount = 0
    let hispanicCount = 0
    let groupCount = 0
    for (let payment of payments) {
      if (payment.package === -1) {
        passedLessons = passedLessons + 1
      }
      if (payment.type === PACKAGE_TYPE.GROUP) {
        groupCount += payment.package
      } else if (payment.type === PACKAGE_TYPE.HISPANIC) {
        hispanicCount += payment.package
      } else if (payment.type === PACKAGE_TYPE.FAMILY) {
        familyCount += payment.package
      } else {
        individualCount += payment.package
      }
      balanceCount = balanceCount + payment.package
    }

    const { packages, euroPackages, usdPackages, kztPackages } = await fetchStudentPackages(uid)
    dispatch(setIsStudent(true))

    dispatch(
      setProfileAction({
        ...profileData,
        id: uid,
        balanceCount,
        passedLessons,
        packages,
        euroPackages,
        usdPackages,
        kztPackages,
        voxData,
        payments,
        hispanicCount,
        groupCount,
        individualCount,
        familyCount,
        showNewHomework: profileData.showNewHomework || 1,
      })
    )

    return profileData
  }
}

export const getManagerProfile = (
  uid: string
): ((dispatch: Dispatch<ProfileActionsTypes>) => Promise<IProfile>) => {
  return async (dispatch: Dispatch<ProfileActionsTypes>) => {
    const profileData = await fetchManagerProfileByUid(uid)

    if (profileData === undefined) {
      throw Object({ code: REGISTER_CODES.other.manager_not_found })
    }

    dispatch(
      setProfileAction({
        ...profileData,
        id: uid,
        role: ROLES.MANAGER,
        manager: true,
      })
    )
    return {
      ...profileData,
      id: uid,
      role: ROLES.MANAGER,
      manager: true,
    }
  }
}
export const getSalesManagerProfile = (
  uid: string
): ((dispatch: Dispatch<ProfileActionsTypes>) => Promise<IProfile>) => {
  return async (dispatch: Dispatch<ProfileActionsTypes>) => {
    const profileData = await fetchSalesManagerProfileByUid(uid)

    if (profileData === undefined) {
      throw Object({ code: REGISTER_CODES.other.sales_manager_not_found })
    }

    dispatch(
      setProfileAction({
        ...profileData,
        id: uid,
        role: ROLES.SALES_MANAGER,
        manager: true,
      })
    )
    return {
      ...profileData,
      id: uid,
      role: ROLES.SALES_MANAGER,
      manager: true,
    }
  }
}

export const updateProfilePromoCode = (promoCode: string) => {
  return async (dispatch: Dispatch<ProfileActionsTypes>) => {
    const { uid } = authRef.currentUser

    try {
      await studentsRef.doc(uid).update({
        promoCode,
      })

      dispatch(setStudentPromoCode(promoCode))
    } catch (err) {
      console.error(err)
    }
  }
}

export const setEditedProfile = (profile: IStudentProfile) => async (
  dispatch: Dispatch<ProfileActionsTypes>
) => {
  await studentsRef.doc(profile.id).set(profile)
  dispatch(setProfileAction({ ...profile, isFirstLogin: false }))
}

export const updateStudentProfile = (profile: IStudentProfile) => {
  return async (dispatch: Dispatch<ProfileActionsTypes>, getState) => {
    try {
      const state: RootState = getState()
      const oldProfile = state.profile.profile
      await studentsRef.doc(profile.id).update({
        lastName: profile.lastName,
        name: profile.name,
        level: profile.level,
        goals: profile.goals,
        interests: profile.interests,
        skype: profile.skype,
        phone: profile.phone,
        birthDate: profile.birthDate,
        avatar: profile.avatar || '',
        lang: profile.lang,
        timeZone: profile.timeZone,
        showNewHomework: profile.showNewHomework,
      })

      dispatch(setProfileAction({ ...oldProfile, ...profile }))
      toast(i18next.t('SuccessProfileUpdate'), {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'student-message-success',
      })
    } catch (e) {
      toast(i18next.t('FailedProfileUpdate'), {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'student-message-failed',
      })
    }
  }
}

export const updateStudentProfileMiniCourse = (id: string, timeZone: string) => async (
  dispatch: Dispatch<ProfileActionsTypes>,
  getState
) => {
  try {
    const state: RootState = getState()
    const oldProfile = state.profile.profile
    await studentsRef.doc(id).update({
      timeZone,
    })

    dispatch(setProfileAction({ ...oldProfile, timeZone }))
  } catch (e) {
    toast('Не удалось сохранить изменения', {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'student-message-failed',
    })
  }
}

export const updateStudentCountOfCancel = (id: string, month: number) => async (
  dispatch: Dispatch<ProfileActionsTypes>,
  getState
) => {
  try {
    const state: RootState = getState()
    const oldProfile = state.profile.profile as IStudentProfile
    const profile = state.profile.profile as IStudentProfile

    const countOfCancel =
      profile.countOfCancel && profile.countOfCancel?.m === month
        ? { m: month, c: profile.countOfCancel.c + 1 }
        : { m: month, c: 1 }
    await studentsRef.doc(id).update({
      countOfCancel: countOfCancel,
    })
    dispatch(setProfileAction({ ...oldProfile, countOfCancel: countOfCancel }))
  } catch (e) {}
}

export const updateStudentProfileTimeZone = (id: string, timeZone: string) => async (
  dispatch: Dispatch<ProfileActionsTypes>,
  getState
) => {
  try {
    const state: RootState = getState()
    const oldProfile = state.profile.profile
    await studentsRef.doc(id).update({
      timeZone,
    })

    dispatch(setProfileAction({ ...oldProfile, timeZone }))
    toast('Изменения успешно сохранены', {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'student-message-success',
    })
  } catch (e) {
    toast('Не удалось сохранить изменения', {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'student-message-failed',
    })
  }
}

export const updateStudentProfileRegistration = (id: string) => async (
  dispatch: Dispatch<ProfileActionsTypes>,
  getState
) => {
  try {
    const state: RootState = getState()
    const studentProfile = state.profile.profile
    await studentsRef.doc(id).update({
      isSelfRegistration: true,
    })

    dispatch(setProfileAction({ ...studentProfile, isSelfRegistration: true }))
  } catch (e) {
    toast('Не удалось сохранить изменения', {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'student-message-failed',
    })
  }
}

export const updateStudentProfileLang = (id: string, timeZone: string) => async (
  dispatch: Dispatch<ProfileActionsTypes>,
  getState
) => {
  try {
    const state: RootState = getState()
    const oldProfile = state.profile.profile
    await studentsRef.doc(id).update({
      timeZone,
    })

    dispatch(setProfileAction({ ...oldProfile, timeZone }))
    toast('Изменения успешно сохранены', {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'student-message-success',
    })
  } catch (e) {
    toast('Не удалось сохранить изменения', {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'student-message-failed',
    })
  }
}

export const updateStudentProfileTranslate = (id: string, lang: string) => {
  return async (dispatch, getState) => {
    try {
      const state: RootState = getState()
      const oldProfile = state.profile.profile
      dispatch(setProfileAction({ ...oldProfile, ln: lang }))
      await studentsRef.doc(id).update({
        ln: lang,
      })

      toast('Изменения успешно сохранены', {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'student-message-success',
      })
    } catch (e) {
      toast('Не удалось сохранить изменения', {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'student-message-failed',
      })
    }
  }
}

export const setStudentProfileTranslate = async profile => {
  try {
    await studentsRef.doc(profile.id).update({
      ln: 'ru',
    })
  } catch (e) {
    toast('Не удалось сохранить изменения', {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'student-message-failed',
    })
  }
}

export const setStudentMiniCourses = async profile => {
  try {
    await studentsRef.doc(profile.id).set({
      ...profile,
      miniCourse: {},
    })
  } catch (e) {
    toast('Не удалось сохранить изменения', {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'student-message-failed',
    })
  }
}

export const uploadAvatar = (file: File) => {
  return async dispatch => {
    await storageRef.ref('avatars/' + file.name).put(file)
  }
}

export const removeAvatar = (fileName: string) => {
  return async dispatch => {
    await storageRef
      .ref('avatars')
      .child(fileName)
      .delete()
  }
}

export const updateStudentAvatar = (file: File) => {
  return async (dispatch, getState) => {
    const state: RootState = getState()
    const studentProfile: IStudentProfile = state.profile.profile as IStudentProfile
    await dispatch(uploadAvatar(file))

    const avatarUrl = await storageRef
      .ref('avatars')
      .child(file.name)
      .getDownloadURL()

    dispatch(
      updateStudentProfile({
        ...state.profile.profile,
        avatar: avatarUrl,
      } as IStudentProfile)
    )

    const avatarName = getAvatarNameFromUrl(studentProfile.avatar)
    if (avatarName && avatarName !== '') await dispatch(removeAvatar(avatarName))

    return
  }
}

export const updateBusinessHoursProfile = (userID: string, data: IBusinessHours[]) => {
  return async (dispatch: Dispatch<ProfileActionsTypes>) => {
    await usersRef.doc(userID).update({
      businessHours: data,
      bhUpdated: firebaseTimestamp.now(),
    })

    dispatch(updateBusinessHoursProfileAction(data))
  }
}

export const getStudentWordsCount = () => {
  return async (dispatch, getState) => {
    const state: RootState = getState()
    const words = state.words.words

    if (words.length === 0) {
      dispatch(getWordsAndSave())
    }
  }
}

export const saveStudentNotes = async (studentId: string, notesData: INotes) => {
  try {
    await studentsRef
      .doc(studentId)
      .collection('notes')
      .doc()
      .set(notesData)

    const studentSnap = await studentsRef.doc(studentId).get()
    if (studentSnap.exists) {
      const student = studentSnap.data()
      // if (student.isTgOn) {
      //   await sendDataToStudentTelegram(notesData, student.notifications.cid)
      // }
    }

    toast(i18next.t('NotesSaved'), {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'student-message-success',
    })
  } catch (err) {
    toast(i18next.t('FailedNotesSave'), {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'student-message-failed',
    })
  }
}

let latestDoc = null

export const getStudentNotes = (isFirstPage?: boolean) => {
  return async dispatch => {
    const { uid } = authRef.currentUser
    let snapshot
    if (isFirstPage) {
      snapshot = await studentsRef
        .doc(uid)
        .collection('notes')
        .orderBy('date', 'desc')
        .limit(30)
        .get()
    } else {
      snapshot = await studentsRef
        .doc(uid)
        .collection('notes')
        .orderBy('date', 'desc')
        .startAfter(latestDoc || 0)
        .limit(30)
        .get()
    }

    if (snapshot.empty) {
      dispatch(setListLoaded())
      return
    } else {
      latestDoc = snapshot.docs[snapshot.docs?.length - 1]
      if (snapshot.size < 30) dispatch(setListLoaded())
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as INotes[]
    }
  }
}

export const updateStudentNote = async (n: INotes, note: string, audio?: string) => {
  const { uid } = authRef.currentUser
  if (audio) {
    await studentsRef
      .doc(uid)
      .collection('notes')
      .doc(n.id)
      .update({ notesText: note, audio: audio })
  } else {
    await studentsRef
      .doc(uid)
      .collection('notes')
      .doc(n.id)
      .update({ notesText: note })
  }
}

export const deleteStudentNote = async (noteId: string) => {
  const { uid } = authRef.currentUser
  return studentsRef
    .doc(uid)
    .collection('notes')
    .doc(noteId)
    .delete()
}

export const checkAuth = () => {
  return (dispatch: Dispatch<AuthActionsTypes | ProfileActionsTypes>) => {
    return authRef.onAuthStateChanged((authUser: any) => {
      if (authUser) {
        dispatch(userLoginAction())
      } else {
        dispatch(userLogoutAction())
        dispatch(setIsStudent(false))
      }
    })
  }
}

export const registerUser = (profileData, chosenRole) => dispatch => {
  return secondaryApp
    .auth()
    .createUserWithEmailAndPassword(profileData.email, profileData.password)
    .then(res => {
      delete profileData.password
      const { uid } = res.user
      res.user.sendEmailVerification()
      if (chosenRole === ROLES.TEACHER || chosenRole === ROLES.MANAGER_ASSISTANT) {
        usersRef.doc(uid).set({ ...profileData, isEnrolmentClosed: true, isEmailOn: true })
      } else if (chosenRole === ROLES.MANAGER) {
        managersRef.doc(uid).set({ ...profileData })
      } else if (chosenRole === ROLES.STUDENT) {
        studentsRef.doc(uid).set({ ...profileData, id: uid })
      } else if (chosenRole === ROLES.SALES_MANAGER) {
        salesManagersRef.doc(uid).set({ ...profileData, id: uid })
      }
      return uid
    })
    .then(uid => {
      secondaryApp.auth().signOut()
      return uid
    })
}

export const getProfileFiles = (userID: string) => {
  return async (dispatch: Dispatch<ProfileActionsTypes>) => {
    const files = await getFiles(userID)
    dispatch(setProfileFilesAction(files))
    return files
  }
}

export const getFiles = async (userID: string) => {
  const snapshotDocs = await libraryRef.doc(`${userID}_${docs.type}`).get()
  const snapshotImage = await libraryRef.doc(`${userID}_${image.type}`).get()
  const snapshotVideo = await libraryRef.doc(`${userID}_${video.type}`).get()
  const snapshotAudio = await libraryRef.doc(`${userID}_${audio.type}`).get()

  let types = []
  if (snapshotDocs.data()) {
    types.push({ type: docs.type, data: snapshotDocs.data() })
  }
  if (snapshotImage.data()) {
    types.push({ type: image.type, data: snapshotImage.data() })
  }
  if (snapshotVideo.data()) {
    types.push({ type: video.type, data: snapshotVideo.data() })
  }
  if (snapshotAudio.data()) {
    types.push({ type: audio.type, data: snapshotAudio.data() })
  }
  let files = [] as ILibraryFile[]

  for (let item of types) {
    let fileKeys = Object.keys(item.data)
    for (let key of fileKeys) {
      let file = {} as ILibraryFile
      file.type = item.type
      file.url = key
      file.file_name = item.data[key]
      files.push(file)
    }
  }
  return files
}

export const updateProfileFiles = (fileData: ILibraryFile, userID: string) => {
  return async (dispatch: Dispatch<ProfileActionsTypes>) => {
    try {
      const snapshot = await libraryRef.doc(`${userID}_${fileData.type}`).get()

      let data = snapshot.data() ? snapshot.data() : {}

      const { file_name, url, type } = fileData
      if (!file_name && type) {
        const values = queryString.parse(url)

        if (values.token) {
          await storageRef.refFromURL(url).delete()
        }

        await deleteFileFromClassrooms(url)
        delete data[url]
        dispatch(deleteProfileFileAction(url))
      } else {
        if (!data[url]) {
          data[url] = file_name
          dispatch(updateProfileFileAction({ url, file_name, type }))
        }
      }

      await libraryRef.doc(`${userID}_${fileData.type}`).set(data)
    } catch (error) {
      logger.error(error)
    }
  }
}

export const getProfileRefreshToken = (uid: string) => {
  return async (dispatch: Dispatch<ProfileActionsTypes>) => {
    const snapshot = await usersRef.doc(uid).get()
    const profileData = snapshot.data() as IProfile
    return
  }
}

export const deleteFileFromClassrooms = async (url: string) => {
  const { uid } = authRef.currentUser
  let classrooms = await classroomsRef.where('uid', '==', uid).get()
  await Promise.all(
    classrooms.docs.map(async classroom => {
      let files = await classroomsRef
        .doc(classroom.id)
        .collection('files')
        .where('url', '==', url)
        .get()
      await Promise.all(
        files.docs.map(async file => {
          await file.ref.delete()
        })
      )
    })
  )
}

export const getAutoTeachersSelection = async (): Promise<IProfile[]> => {
  let snapshot: app.firestore.Query<app.firestore.DocumentData> = usersRef

  const usersDocs = await snapshot.get()
  let result = usersDocs.docs.map(doc => {
    const levelsOfStudents = doc.data().levelsOfStudents

    return {
      ...doc.data(),
      id: doc.id,
      levelsOfStudents: levelsOfStudents?.length > 0 ? levelsOfStudents : [LEVELS.A1],
    }
  }) as IProfile[]

  result = result.filter(item => item.role === 'TEACHER' || item.role === 'METHODIST')
  return result
}
