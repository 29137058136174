import styled from 'styled-components'

export const Container = styled.div<{ isBalance?: boolean }>`
  position: relative;
  background-color: ${props =>
    props.isBalance
      ? `${props.theme.colors.bgDashboard}80`
      : `${props.theme.colors.hmStartStatus}80`};
  width: 240px;
  height: 180px;
  border-radius: ${props => (props.isBalance ? '0 0 20px 20px' : '20px')};
  align-self: center;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: ${props => props.isBalance && `3px 3px 10px rgb(0 0 0 / 10%)`};
  margin-top: ${props => props.isBalance && '40px'};
`

export const BonusPresent = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #f3f5f9;
  width: 70px;
  cursor: pointer;
  &:hover {
    color: #4284ff;
  }
`

export const PackagesTabs = styled.div<{ count?: number }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  position: absolute;
  left: 0;
  top: -40px;
  & :last-child {
    position: absolute;
    right: 0;
  }
  & :first-child {
    position: absolute;
    left: 0;
  }

  & .second {
    position: absolute;
    left: ${({ count }) => {
      if (count === 3) return '70px'
      if (count === 4) return '54px'
      if (count === 5) return '45px'
      return '50px'
    }};
  }

  & .third {
    position: absolute;
    left: 106px;
    left: ${({ count }) => {
      if (count === 4) return '106px'
      if (count === 5) return '91px'
      return '106px'
    }};
  }
  & .fourth {
    position: absolute;
    left: 138px;
  }
`

export const PackageTab = styled.div<{ isActive?: boolean; count?: number }>`
  width: ${({ count }) => {
    if (count === 2) return '135px'
    if (count === 3) return '100px'
    if (count === 4) return '80px'
    if (count === 5) return '60px'
    return '50px'
  }};
  height: 100%;
  border-radius: ${({ count }) => (count === 4 ? '15px 15px 0 0' : '20px 20px 0 0')};
  box-shadow: 2px 0px 6px 0px #4d60841a;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? '#AEC0D6' : '#C2CFE0')};
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  z-index: ${({ isActive }) => isActive && 10} !important;
  color: #fff;
  cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
`
