import { Tooltip, withStyles } from '@material-ui/core'
import styled from 'styled-components'

export const Course = styled.div<{ isPopular?: boolean; isBlackFriday?: boolean }>`
  position: relative;
  min-width: 220px;
  height: 370px;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  letter-spacing: 0.01em;
  transition: all 0.5s ease;
  z-index: 1;

  ${({ isPopular, isBlackFriday }) =>
    isPopular
      ? `
        background: linear-gradient(white, white) padding-box,linear-gradient(90deg, #F86594 0%, #FDB931 100%) border-box;
        border: 2px solid transparent;
        border-radius: 16px;
      `
      : isBlackFriday
      ? `
        border: 2px solid #1C1C1C;
        `
      : `
        border: 2px solid rgba(225, 236, 255, 1);
      `}

  &:hover {
    border: 2px solid rgba(255, 184, 0, 1);
    box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.06);
  }
`

export const NumberLessons = styled.span<{ isPopular?: boolean }>`
  font-size: 32px;
  font-weight: 700;
  line-height: 43.58px;
  letter-spacing: 0.03em;
  color: transparent;
  background: ${({ isPopular }) =>
    isPopular ? 'linear-gradient(90deg, #F86594 0%, #FDB931 100%)' : 'rgba(144, 164, 189, 1)'};

  -webkit-background-clip: text;
  background-clip: text;
`

export const CourseClass = styled.div<{ isPopular?: boolean }>`
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 20px 0;
  font-family: 'Open Sans';

  color: transparent;
  background: ${({ isPopular }) =>
    isPopular ? 'linear-gradient(90deg, #F86594 0%, #FDB931 100%)' : 'rgba(144, 164, 189, 1)'};

  -webkit-background-clip: text;
  background-clip: text;
`

export const BonusLessons = styled.span`
  font-size: 14px;
  color: rgba(144, 164, 189, 1);
  font-weight: bold;
  line-height: 19px;
  transition: all 0.5s ease;
  flex-grow: 1;
  margin-bottom: -15px;
  font-family: 'Open Sans';
`

export const LessonPrice = styled.span`
  color: rgba(65, 71, 82, 1);
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  flex: 1;
  display: flex;
  align-items: center;
`

export const Price = styled.span<{ isPopular?: boolean }>`
  font-weight: 600;
  font-size: 28px;
  line-height: 33px;
  ${Course}:hover & {
    color: rgba(255, 146, 45, 1);
  }
  color: ${({ isPopular }) => (isPopular ? 'transparent' : 'rgba(66, 132, 255, 1)')};
  background: ${({ isPopular }) =>
    isPopular ? 'linear-gradient(90deg, #F86594 0%, #FDB931 100%)' : 'rgba(144, 164, 189, 1)'};

  -webkit-background-clip: text;
  background-clip: text;
`

export const ButtonText = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
`

export const LastPrice = styled.span`
  font-size: 18px;
  line-height: 19px;
  text-decoration: line-through;
  flex-grow: 1;
  font-weight: 600;
  margin-top: 3px;
`

export const BuyButton = styled.button<{ isPopular?: boolean; isBlackFriday?: boolean }>`
  color: rgba(255, 255, 255, 1);
  background: ${props =>
    props.isPopular
      ? 'linear-gradient(90deg, #F86594 0%, #FDB931 100%)'
      : props.isBlackFriday
      ? '#1C1C1C'
      : 'linear-gradient(270.29deg, rgba(66, 132, 255, 1) 18.37%, rgba(66, 132, 255, 1) 99.74%)'};

  letter-spacing: 0.01em;
  border-radius: 12px;
  padding: 5px;
  transition: all 0.5s ease;
  ${Course}:hover & {
    background: linear-gradient(
      270.29deg,
      rgba(255, 146, 45, 1) 18.37%,
      rgba(255, 184, 0, 1) 99.74%
    );
  }
  width: 140px;
`
export const Badge = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
  transform: translateY(-50%);
  background: linear-gradient(90deg, #f86594 0%, #fdb931 100%);
  font-size: 12px;
  font-weight: 600;
  line-height: 14.98px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  border-radius: 4px;
  color: #fff;
`
export const Economy = styled.div`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  letter-spacing: 0.01em;
  text-align: center;
`
export const IconContainer = styled.div`
  position: absolute;
  right: 14px;
  top: 21px;
  width: 16px;
  height: 16px;

  & svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`

export const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#ffe3c9',
    borderRadius: 20,
    padding: '20px 19px',
    color: '#414752',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '19.07px',
    letterSpacing: '0.01em',
    marginTop: '-10px',
  },
}))(Tooltip)

export const GroupDesc = styled.div`
  color: rgba(126, 146, 172, 1);
  font-size: 14px;
  font-weight: 700;
  line-height: 19.07px;
  letter-spacing: 0.03em;
`
