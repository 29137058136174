import styled from 'styled-components'

export const PromoCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: flex-start;
  border-radius: 20px;
  padding: 20px 0;
  line-height: 19px;
`

export const PromoCodeInputContainer = styled.div<{ isMini: boolean }>`
  position: relative;
  background: rgba(225, 236, 255, 0.5);
  flex: 1;
  border-radius: 20px;
  display: flex;
  justify-content: ${props => (props.isMini ? 'start' : 'center')};
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  height: 94px;
  padding-left: ${props => (props.isMini ? '20px' : '0')};
  & svg {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-24%, -47%);
  }
`

export const PromoCode = styled.input<{
  CancelImg: string
  status: string
  promocodeLength: number
}>`
  background: rgba(255, 255, 255, 1);
  /* border: 2px solid rgba(225, 236, 255, 1); */
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(65, 71, 82, 1);
  height: 40px;
  padding: 0 5px 0 20px;
  letter-spacing: 0.01em;
  min-width: 390px;
  border: ${props =>
    props.promocodeLength === 0
      ? 'none'
      : props.status === 'fail'
      ? '2px solid #DD2E44'
      : props.status === 'success'
      ? '2px solid #27AE60'
      : 'none'};

  ::placeholder {
    color: rgba(144, 164, 189, 1);
    font-size: 14px;
    letter-spacing: normal;
  }

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    margin-left: 0.5vw;
    background-image: url(${props => props.CancelImg});
    cursor: pointer;
  }
`

export const PromoText = styled.span`
  color: rgba(144, 164, 189, 1);
  font-size: 14px;
  font-weight: 400;
  flex: 1;
`

export const PromoButton = styled.button`
  flex: 1;
  color: rgba(66, 132, 255, 1);
  font-size: 14px;
  font-weight: 600;
  background: linear-gradient(90deg, #9ad9fc 0%, #4284ff 75%);
  text-align: center;
  color: #e1ecff;
  border-radius: 20px;
  padding: 13px 0;
`

export const PromoCodeStatus = styled.div<{ status: string }>`
  color: ${props =>
    props.status === 'pending'
      ? 'rgba(144, 164, 189, 1)'
      : props.status === 'success'
      ? 'rgba(39, 174, 96, 1)'
      : 'rgba(221, 46, 68, 1)'};
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Check = styled.img`
  margin-right: 0.4vw;
`

export const Loader = styled.img`
  margin-right: 0.4vw;
  width: 24px;
`

export const Container = styled.div`
  position: relative;
  width: auto;
  height: inherit;
  display: flex;
  align-items: center;
  flex: 1;
  background: rgba(225, 236, 255, 0.5);
  border-radius: 20px;
  text-align: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  height: 94px;
  padding: 0 30px;
  gap: 47px;
  & svg {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-24%, -47%);
  }
`
export const SuspenseContainer = styled.div`
  position: absolute;
  right: 80px;
`
