import { InterestCard } from '@pages/student/Profile/student-profile.styles'
import { TextArea } from '@pages/teacher/Classroom/classroom.styles'
import { STUDENT_RATES } from '@utils/constants'
import styled, { css } from 'styled-components'
import CardEmailIcon from '../Icons/CardEmailIcon'
import CardPhoneIcon from '../Icons/CardPhoneIcon'
import CardSkypeIcon from '../Icons/CardSkypeIcon'
import CardTelegramIcon from '../Icons/Telegram.'
export const StudentCard = styled.div`
  width: 1070px;
  height: 625px;
  background: #f3f5f9;
  border-radius: 16px;
  padding: 30px;
  position: relative;
  display: flex;
  .close-button {
    position: absolute;
    right: 13px;
    top: 13px;
    cursor: pointer;
  }
  .edit-button {
    position: absolute;
    right: 5px;
    top: 0px;
  }
  .edit-btn {
    display: none;
    position: absolute;
    right: -10px;
    top: -6px;
    z-index: 9999;
  }
  .block-main {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-right: 30px;
  }
`

export const EditLevel = styled.div<{ active?: boolean }>`
  display: ${props => (props.active ? 'flex' : 'none')};
  flex-direction: column;
  height: 217px;
  width: 113px;
  background: #f3f5f9;
  border-radius: 7px;
  position: absolute;
  padding: 10px 15px 0px 15px;
  top: 185px;
  left: 15px;
`

export const EditInterests = styled(EditLevel)`
  width: 350px;
  height: 353px;
  top: 207px;
  .edit-container {
    height: 293px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
`

export const EditGoals = styled.div<{ active?: boolean }>`
  display: ${props => (props.active ? 'flex' : 'none')};
  flex-direction: column;
  width: 202px;
  height: 251px;

  background: #f3f5f9;
  border-radius: 7px;
  position: absolute;
  padding: 10px 15px 0px 15px;
  top: 185px;
  right: 15px;
`
export const WorksheetStartTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #1b3b77;
`
export const WorksheetStartDesc = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #90a4bd;
  margin-top: 10px;
  margin-bottom: 20px;
`

export const WorksheetStartInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px 46px 0px 46px;
`

export const PersonalDataContainter = styled.div`
  width: 410px;
  .personal-content {
    width: 380px;
    height: 540px;
    background: #ffffff;
    border-radius: 20px;
    padding: 25px 15px;
    position: relative;
  }
  .personal-info {
    display: flex;
  }
  .personal-name {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: #1b3b77;
    margin-left: 20px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
  }
  .personal-years {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #414752;
    margin-right: 10px;
  }
  .balance-count {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #414752;
    margin-right: 5px;
  }
`

export const PersonalRate = styled.div<{ rate?: STUDENT_RATES }>`
  border-radius: 4px;
  height: 18px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-transform: lowercase;
  background: ${props =>
    props.rate === STUDENT_RATES.child
      ? '#FF922D'
      : props.rate === STUDENT_RATES.couple
      ? '#BB6BD9'
      : '#4284FF'};
`

export const LevelContainer = styled.div<{ show: boolean }>`
  display: flex;
  width: 100%;
  height: auto;
  position: relative;
  .show {
    display: none;
  }

  &:hover {
    background: ${props => (props.show ? '#f3f5f9' : 'none')};
    border-radius: 20px;
    .show {
      display: block;
    }
  }
`

export const InterestsContainer = styled.div<{ show: boolean }>`
  width: 350px;
  height: 222px;
  padding: 10px 5px 10px 15px;
  position: relative;
  margin-top: 10px;
  .show {
    display: none;
  }
  &:hover {
    background: ${props => (props.show ? '#f3f5f9' : 'none')};
    border-radius: 7px;
    .show {
      display: block;
    }
  }
`

export const InterestsCard = styled(InterestCard)``

export const AddButton = styled.button`
  width: 40px;
  height: 40px;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;

  color: #c2cfe0;
  background: #f3f5f9;
  border-radius: 4px;
`

export const GoalsContainer = styled.div<{ show: boolean }>`
  width: 202px;
  height: 112px;
  padding: 10px 5px 10px 15px;
  position: relative;
  .show {
    display: none;
  }

  &:hover {
    background: ${props => (props.show ? '#f3f5f9' : 'none')};
    border-radius: 7px;
    .show {
      display: block;
    }
  }
`

export const CardsContainer = styled.div`
  display: flex;

  flex-wrap: wrap;
  overflow: hidden;
  align-content: flex-start;

  ::-webkit-scrollbar {
    width: 5px;
    height: 100%;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 2px;
  }
  ${InterestsContainer}:hover & {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #c2cfe0 #f3f5f9;
    ::-webkit-scrollbar-thumb {
      background: #c2cfe0;
    }
  }
  ${GoalsContainer}:hover & {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #c2cfe0 #f3f5f9;
    ::-webkit-scrollbar-thumb {
      background: #c2cfe0;
    }
  }
`

export const LevelCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 83px;
  height: 40px;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.01em;
  background: #c2cfe0;
  border-radius: 4px;
  color: #ffffff;
`

export const GoalsCard = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0px 15px;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  background: #c2cfe0;
  border-radius: 4px;
  color: #ffffff;
  margin-bottom: 10px;
`
export const StudentCardHeader = styled.p<{ child?: boolean }>`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #1b3b77;
  margin-bottom: ${props => (props.child ? '10px' : '15px')};
`

export const ToCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
`

export const ScheduleContainter = styled.div`
  width: 300px;
  margin-bottom: 15px;
  .schedule-content {
    width: 270px;
    height: 187px;
    background: #ffffff;
    border-radius: 20px;
    padding: 15px;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: #90a4bd;
    position: relative;
  }
`

export const ScheduleList = styled.div`
  height: 150px;
  overflow: hidden;
`

export const TeacherInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  .teacher-info {
    display: flex;
    align-items: center;
    .teacher-name {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin-right: 5px;
      color: #414752;
    }
  }
`

export const SliderButtons = styled.div`
  width: 61px;
  height: 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  background: rgba(194, 207, 224, 0.15);
  border-radius: 18px;
  position: absolute;
  right: 15px;
  top: 15px;
`

export const ScheduleBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`
export const WeekBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`
export const WeekLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 3px;
  text-transform: capitalize;
  max-width: 190px;
`
export const TeacherNameLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
  display: inline-block;
`

export const TeacherName = styled.span`
  display: inline;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  align-items: center;
  cursor: pointer;
  /* Blue_Light_Accent Text */
  color: #4284ff;
`
export const ContactBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  overflow: hidden;
  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }
  div.phone {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  .copy {
    display: none;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &:hover {
    .copy {
      display: block;
      z-index: 999;
    }
  }
`

export const ContactsContainter = styled.div`
  width: 300px;
  height: 158px;
  margin-bottom: 15px;
`
export const Label = styled.span`
  margin: 0 10px;
  font-weight: 600;
  font-size: ${p => p.theme.fontSizes.small};
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${p => p.theme.colors.textDark};
  transition: all 0.1s ease-in-out;
  text-overflow: ellipsis;
`

export const ContactField = styled.input`
  width: 202px;
  border-bottom: 1px solid #d3d8dd;
  background: none;
  font-size: ${p => p.theme.fontSizes.medium};
  line-height: 19px;
  font-weight: 700;
  transition: all 0.1s ease-in-out;
  padding-bottom: 6px;
  margin-left: 15px;
  color: ${p => p.theme.colors.textDark};
  &:focus {
    border-bottom: 2px solid ${p => p.theme.colors.bgBlue};
  }
`

export const EmailIcon = styled(CardEmailIcon)``
export const PhoneIcon = styled(CardPhoneIcon)``
export const SkypeIcon = styled(CardSkypeIcon)``
export const TelegramIcon = styled(CardTelegramIcon)``
export const Contacts = styled.div<{ editing?: boolean; manager?: boolean }>`
  width: 270px;
  height: 101px;
  background: ${({ editing, theme }) => (editing ? theme.colors.bgDashboard : '#fff')};
  border-radius: 20px;
  padding: ${({ editing }) => (editing ? '13px 15px' : '15px 15px 5px')};
  position: absolute;
  transition: all 0.1s ease-in-out;
  ${TelegramIcon} {
    rect {
      fill: #c2cfe0;
    }
  }
  ${({ editing }) =>
    editing &&
    css`
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    `};

  .show {
    display: none;
  }

  &:hover {
    ${({ editing, manager }) =>
      !editing &&
      manager &&
      css`
        background: rgba(223, 234, 255, 0.3);
        .show {
          display: block;
        }
      `};
  }

  ${ContactBlock} {
    ${props =>
      props.editing &&
      css`
        height: 32px;
        margin-bottom: 6px;
        .copy {
          display: none;
        }
        ${Label} {
          color: ${p => p.theme.textColors.greyMiddle};
          font-weight: 700;
          font-size: ${p => p.theme.fontSizes.medium};
          line-height: 19px;
          margin: 0 15px;
        }
        ${PhoneIcon} {
          circle {
            fill: ${props.theme.colors.bgBlue};
          }
        }
        ${SkypeIcon} {
          path:nth-child(1) {
            fill: ${props.theme.colors.bgBlue};
          }
        }
        ${TelegramIcon} {
          rect {
            fill: #419fd9;
          }
        }
        ${EmailIcon} {
          path:nth-child(1) {
            fill: #37aee2;
          }
        }
      `};
  }
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.bgLightSide1};
    border-radius: 2px;
    margin: 10px 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 2px;
  }
`

export const CommentsTextArea = styled(TextArea)`
  font-size: 14px;
  line-height: 19px;
  color: #414752;
  padding: 10px;
  height: 390px;
  ::placeholder {
    font-weight: normal;
    color: rgba(144, 164, 189, 0.5);
  }
`
export const Tutor = styled.div`
  display: flex;
  text-align: center;
  font-size: 16px;
  color: #414752;
  padding: 10px;
  padding-left: 30px;
  height: 20px;
  border-radius: 20px;
  background-color: #ffffff;
  width: 88%;
  margin-bottom: 15px;
`

export const ProsgressContainter = styled.div`
  width: 300px;
  .progress-content {
    width: 270px;
    height: 120px;
    background: #ffffff;
    border-radius: 20px;
    padding: 15px;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: rgba(144, 164, 189, 0.8);
  }
`
export const CourseBlock = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProgressBlock = styled(CourseBlock)`
  margin-top: 15px;
`

export const ProgressRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CourseLabel = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #414752;
  margin-top: 5px;
`

export const ProgressValue = styled.div`
  width: 61px;
  height: 26px;
  background: #4284ff;
  border-radius: 40px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`

export const ProgressLabel = styled(CourseLabel)`
  width: 60px;
`

export const CommentsContainter = styled.div`
  width: 300px;
  .comments-content {
    width: 260px;
    height: 390px;
    background: #ffffff;
    border-radius: 20px;
    padding: 20px;
    position: relative;
  }
`

export const Div = styled.div``

export const LevelProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background: white;
  position: relative;
  border-radius: 50%;
`

export const LevelProgeessCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 42px;
  height: 42px;

  background: #f3f5f9;
  border-radius: 50%;
`

export const LevelProgressTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;

  /* Headline dark */

  color: #4d6084;
`
export const SaveBtn = styled.button`
  background: #c2cfe0;
  border-radius: 20px;
  min-width: 110px;
  min-height: 40px;
  float: right;
  color: white;
  padding: 10px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;

  &:hover {
    background: #7e92ac;
  }
`
export const Balance = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  display: flex;
  align-items: center;
  /* Back_Grey_1 */
  color: #c2cfe0;
`
export const BalanceCount = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #1b3b77;
  max-width: 55px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* Grey middle_ default text */

  color: #7e92ac;
`
export const SMSIconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 30px;
`

export const ResearchGroups = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  margin-bottom: 10px;
`
