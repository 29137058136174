import React, { useState } from 'react'
import { PACKAGES_DESC } from '@utils/constants'
import { IPackage } from '@models/IProfile'
import { PackageTag } from '@components'
import { Package } from '@components'
import * as Styled from './package.tagbar.styles'
import { useTranslation } from 'react-i18next'

interface Props {
  packages: IPackage[]
  suspensePromoCode: string
  currency: string
  promoCode: string
}

const PackageTagbar = ({ packages, suspensePromoCode, currency, promoCode }: Props) => {
  const [selectedIndex, setSelected] = useState(0)
  const widthArray = [27, 23, 27, 23]

  const tabNames: string[] = packages.map((pkg: IPackage) => PACKAGES_DESC[pkg.type].title)
  const descNames: string[] = packages.map((pkg: IPackage) => PACKAGES_DESC[pkg.type].desc)

  const { t } = useTranslation()
  return (
    <>
      <Styled.TagBar>
        {tabNames.map((tabName: string, index: number) => (
          <PackageTag
            widthProp={widthArray[index]}
            title={t(tabName)}
            key={index}
            index={index}
            selected={selectedIndex}
            setSelected={setSelected}
          />
        ))}
      </Styled.TagBar>
      <Styled.TagDescription>{t(descNames[selectedIndex])}</Styled.TagDescription>
      <Package
        key={packages[selectedIndex].type}
        type={packages[selectedIndex].type}
        currency={currency}
        courses={packages[selectedIndex].courses}
        promoCode={suspensePromoCode === 'success' ? promoCode : ''}
      />
    </>
  )
}

export default PackageTagbar
