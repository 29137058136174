import React from 'react'

const LifeTimePackage = ({ isHovered }: { isHovered: boolean }) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_16757_54083)">
        <path
          d="M11.5152 8.07505C9.34312 8.07505 7.57585 10.0034 7.57585 12.3735C7.57585 14.7298 9.34312 16.6462 11.5152 16.6462C13.6874 16.6462 15.4546 14.7179 15.4546 12.3477C15.4546 9.99151 13.6874 8.07505 11.5152 8.07505ZM11.5152 15.9849C9.67766 15.9849 8.18191 14.3647 8.18191 12.3735C8.18191 10.3678 9.67766 8.73635 11.5152 8.73635C13.3528 8.73635 14.8486 10.3565 14.8486 12.3477C14.8486 14.3535 13.3528 15.9849 11.5152 15.9849ZM12.3358 12.7749C12.454 12.9039 12.454 13.1135 12.3358 13.2425C12.2765 13.3073 12.1989 13.339 12.1213 13.339C12.0437 13.339 11.9661 13.3066 11.9068 13.2425L11.3007 12.5812C11.2437 12.519 11.2122 12.435 11.2122 12.3471V11.0245C11.2122 10.8419 11.348 10.6938 11.5152 10.6938C11.6825 10.6938 11.8183 10.8419 11.8183 11.0245V12.2102L12.3358 12.7749ZM12.7274 2.09751H11.8183V1.10555C11.8183 0.923035 11.6825 0.774902 11.5152 0.774902C11.348 0.774902 11.2122 0.923035 11.2122 1.10555V2.09751H5.1516V1.10555C5.1516 0.923035 5.01585 0.774902 4.84857 0.774902C4.6813 0.774902 4.54554 0.923035 4.54554 1.10555V2.09751H3.63645C2.13282 2.09751 0.90918 3.43269 0.90918 5.07338V13.6703C0.90918 15.311 2.13282 16.6462 3.63645 16.6462H7.27282C7.44009 16.6462 7.57585 16.4981 7.57585 16.3156C7.57585 16.133 7.44009 15.9849 7.27282 15.9849H3.63645C2.46676 15.9849 1.51524 14.9467 1.51524 13.6703V6.72665H14.8486V7.7186C14.8486 7.90112 14.9843 8.04925 15.1516 8.04925C15.3189 8.04925 15.4546 7.90112 15.4546 7.7186V5.07338C15.4546 3.43269 14.231 2.09751 12.7274 2.09751ZM1.51524 6.06534V5.07338C1.51524 3.79707 2.46676 2.75882 3.63645 2.75882H12.7274C13.8971 2.75882 14.8486 3.79707 14.8486 5.07338V6.06534H1.51524Z"
          fill={isHovered ? '#4284FF' : '#7E92AC'}
        />
      </g>
      <defs>
        <clipPath id="clip0_16757_54083">
          <rect
            width="14.5455"
            height="15.8713"
            fill="white"
            transform="translate(0.90918 0.774902)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LifeTimePackage
