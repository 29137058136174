import React, { Suspense, lazy, useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { initDB } from 'react-indexed-db'
import { NAVIGATOR, DBConfig } from '@utils/constants.ts'
import { checkAuth } from '@servise/profileAPI'

import defaultFooter from '@components/DefaultFooter'
import Privacy from './Landing/GooglePage'
import Contacts from './Landing/Contacts'
import Courses from '@routes/courses'
import Course from '@routes/course'
import Test from '@routes/test'
import Lesson from '@routes/lesson'
import { LangEnum } from './models'
import { useTranslation } from 'react-i18next'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import DetailsTeacherInfo from '@pages/teachersInfo/DetailsTeacherInfo'

const StudentLesson = lazy(() => import('./pages/student/Lesson'))
const Login = lazy(() => import('./Landing/Login'))
const Registration = lazy(() => import('./Landing/Registration'))
const SuccessRegister = lazy(() => import('./Landing/Registration/SuccessRegister'))
const Main = lazy(() => import('./Landing/Main'))
const Restore = lazy(() => import('./Landing/Restore'))
const TeacherPage = lazy(() => import('./pages/teacher'))
const ManagerPage = lazy(() => import('./pages/manager'))
const PageNotFound = lazy(() => import('./components/PageNotFound'))
const StudentPage = lazy(() => import('./pages/student'))
const SimpleLesson = lazy(() => import('./pages/student/Lesson/SimpleLesson'))
const TeacherLesson = lazy(() => import('./pages/teacher/Lesson'))
const StudentHomework = lazy(() => import('./pages/student/StudentHomework'))
const StudentMiniLessons = lazy(() => import('./pages/student/StudentMiniLessons'))
const CheckOut = lazy(() => import('./pages/student/Checkout'))
const AutoTeacherSelectionPage = lazy(() => import('./pages/student/AutoTeacherSelection'))
const TeachersInfo = lazy(() => import('./pages/teachersInfo'))

initDB(DBConfig)

function App() {
  const {
    main,
    login,
    register,
    afterRegister,
    restore,
    student,
    manager_teachers,
    teacher_calendar,
    student_lesson,
    teacher_lesson,
    teacher_courseroom,
    auto_teacher_selection,
    teachersInfo,
    detailsTeacherInfo,
  } = NAVIGATOR

  const { i18n } = useTranslation()

  const dispatch = useDispatch()
  moment.locale(i18n.language)
  useEffect(() => {
    dispatch(checkAuth())
    const lang = localStorage.getItem('lang')
    if (!lang) {
      localStorage.setItem('lang', LangEnum.ru)
      i18n.changeLanguage(LangEnum.ru)
    }
    console.log(defaultFooter())
  }, [dispatch, i18n])

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Suspense>
        <Switch>
          <Route exact path={main.path} component={Main} />

          <Route exact path={login.path} component={Login} />
          <Route exact path={register.path} component={Registration} />
          <Route exact path={afterRegister.path} component={SuccessRegister} />

          <Route path={student_lesson.path} component={() => <SimpleLesson />} />
          <Route path={teacher_lesson.path} component={() => <TeacherLesson />} />

          <Route exact path={restore.path} component={Restore} />
          <Route path={manager_teachers.path} component={() => <ManagerPage />} />
          <Route path={teacher_calendar.path} component={() => <TeacherPage />} />
          <Route path={'/privacy'} component={() => <Privacy />} />
          <Route path={'/contacts'} component={() => <Contacts />} />
          <Route path={student.path} component={() => <StudentPage />} />

          <Route exact path={teachersInfo.path} component={() => <TeachersInfo />} />
          <Route exact path={detailsTeacherInfo.path} component={() => <DetailsTeacherInfo />} />
          <Route exact path={auto_teacher_selection.path} component={AutoTeacherSelectionPage} />
          <Route exact path="/courses" component={Courses} />
          <Route exact path="/course/:courseId" component={Course} />
          <Route exact path="/tests" component={Courses} />
          <Route exact path="/tests/:testId" component={Test} />
          <Route exact path="/course/lesson/:courseId/:lessonId" component={Lesson} />
          <Route exact path="/homework/:homeworkId/page/:pageId" component={StudentHomework} />
          <Route
            exact
            path="/homework/:homeworkId/page/:pageId/:studentID"
            component={StudentHomework}
          />
          <Route
            exact
            path="/minicourses/:courseId/:lessonId/page/:pageId"
            component={StudentMiniLessons}
          />

          <Route
            exact
            path="/checkout/:lessons/:type/:currency/:amount/:courseId/:userId/:promoCode"
            component={CheckOut}
          />
          <Route component={PageNotFound} />

          <Route
            exact
            path={`${teacher_courseroom.path}/:studentId/course/:courseId/lesson/:lessonId/page/:pageId`}
            component={StudentLesson}
          />
        </Switch>
      </Suspense>
      <ToastContainer />
    </BrowserRouter>
  )
}

export default App
