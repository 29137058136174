import styled from 'styled-components'

export const OpenButton = styled.button<{ isOpenButton?: boolean }>`
  margin-top: 10px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 700;
  color: #fff;
  height: 40px;
  background: ${({ isOpenButton }) => (isOpenButton ? '#4284ff' : '#FF922D')};
  border-radius: ${({ isOpenButton }) => (isOpenButton ? '10px' : '20px')};
  padding: 8px 16px;
`

export const BookinTeacherModalEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 560px;
  box-sizing: border-box;
  padding: 40px 0;
  & .bull_img {
    margin-top: -20px;
    width: 271px;
    height: 271px;
    align-self: center;
  }
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: 800;
  line-height: 27.24px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #414752;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  letter-spacing: 0.01em;
  text-align: left;
  align-items: center;
  > *:last-child {
    margin-top: 10px;
  }
`
export const Input = styled.input`
  padding: 13px 14px;
  color: #414752;
  border-radius: 10px;
  border: 1px solid #c2cfe0;
  width: 354px;
  &::placeholder {
    color: #c2cfe0;
  }
`
export const SuccessTitle = styled.div`
  font-family: Open Sans;
  font-size: 30px;
  font-weight: 700;
  line-height: 40.85px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #4d6084;
`
export const SuccessSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  text-align: center;
  & > span:last-child {
    font-weight: 400;
  }
`
