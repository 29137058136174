import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { firebaseAnalytics } from '@configs/firebase'

import { declOfNum } from '@utils/helperst'

import { Text } from '@components/Text'
import StudentButton from '../Button'

import EmptyBalanceImg from '../Icons/empty_balance.svg'
import FullBalanceImg from '../Icons/full_balance.svg'
import StartImg from '../Icons/start.svg'

import { theme } from '@styles/Theme'

import { useTranslation } from 'react-i18next'
import { IStudentProfile } from '@models/IProfile'
import { RootState } from '@store/index'
import { useSelector } from 'react-redux'
import * as Styled from './balance.styles'

const Balance = ({
  to,
  balanceCount,
  showPromoCodeModal,
}: {
  to: string
  balanceCount: number | null
  showPromoCodeModal: () => void
}) => {
  const { t } = useTranslation()
  const isBalanceFull = () => balanceCount && balanceCount !== 0
  const student = useSelector((state: RootState) => state.profile.profile as IStudentProfile)
  const goToPaymentPage = () => {
    firebaseAnalytics.logEvent('TOP_UP')
  }
  const [isActiveIndex, setisActiveIndex] = useState(0)
  const [packageBalance, setpackageBalance] = useState({
    count: balanceCount,
    text: 'TotalBalance',
  })

  const handleClickTab = (tab, index) => {
    setisActiveIndex(index)
    setpackageBalance(tab)
  }
  const _renderPackagesTabs = useCallback(() => {
    const tabs = []

    if (student.balanceCount) tabs.push({ count: student.balanceCount, text: 'TotalBalance' })
    if (student.individualCount)
      tabs.push({
        count: student.individualCount,
        text: 'PackagesRussianTitle',
      })
    if (student.familyCount)
      tabs.push({
        count: student.familyCount,
        text: 'PackagesFamilyTitle',
      })
    if (student.hispanicCount)
      tabs.push({
        count: student.hispanicCount,
        text: 'PackagesIspanicTitle',
      })
    if (student.groupCount) tabs.push({ count: student.groupCount, text: 'PackagesGroupTitle' })
    if (tabs.length === 0) return
    if (tabs.length === 2) {
      return (
        <Styled.PackagesTabs count={tabs.length}>
          {tabs.map((tab, i) => (
            <Styled.PackageTab
              count={tabs.length}
              className={i === 1 && tabs.length !== 2 && 'second'}
              isActive={i === isActiveIndex}
              style={{ zIndex: tabs.length - 1 - i }}
              onClick={() => {
                handleClickTab(tab, i)
              }}
            >
              {tab.count}
            </Styled.PackageTab>
          ))}
        </Styled.PackagesTabs>
      )
    }

    if (tabs.length === 3) {
      return (
        <Styled.PackagesTabs count={tabs.length}>
          {tabs.map((tab, i) => (
            <Styled.PackageTab
              count={tabs.length}
              className={i === 1 && 'second'}
              isActive={i === isActiveIndex}
              style={{ zIndex: tabs.length - 1 - i }}
              onClick={() => {
                handleClickTab(tab, i)
              }}
            >
              {tab.count}
            </Styled.PackageTab>
          ))}
        </Styled.PackagesTabs>
      )
    }
    if (tabs.length === 4) {
      return (
        <Styled.PackagesTabs count={tabs.length}>
          {tabs.map((tab, i) => (
            <Styled.PackageTab
              count={tabs.length}
              className={`${i === 1 ? 'second' : ''} ${i === 2 ? 'third' : ''}`}
              style={{ zIndex: tabs.length - 1 - i }}
              isActive={i === isActiveIndex}
              onClick={() => {
                handleClickTab(tab, i)
              }}
            >
              {tab.count}
            </Styled.PackageTab>
          ))}
        </Styled.PackagesTabs>
      )
    }
    if (tabs.length === 5) {
      return (
        <Styled.PackagesTabs count={tabs.length}>
          {tabs.map((tab, i) => (
            <Styled.PackageTab
              count={tabs.length}
              className={`${i === 1 ? 'second' : ''} ${i === 2 ? 'third' : ''}${
                i === 3 ? 'fourth' : ''
              }`}
              style={{ zIndex: tabs.length - 1 - i }}
              isActive={i === isActiveIndex}
              onClick={() => {
                handleClickTab(tab, i)
              }}
            >
              {tab.count}
            </Styled.PackageTab>
          ))}
        </Styled.PackagesTabs>
      )
    }
    return
  }, [
    student.balanceCount,
    student.individualCount,
    student.groupCount,
    student.hispanicCount,
    student.familyCount,
    isActiveIndex,
  ])

  return (
    <Styled.Container isBalance={isBalanceFull()}>
      {balanceCount !== 0 && _renderPackagesTabs()}
      <div>
        <Text
          display="additionalMediumText"
          style={{ color: isBalanceFull() && theme.colors.bgNav }}
        >
          {t(packageBalance.text)}:
        </Text>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'baseline',
        }}
      >
        <img src={isBalanceFull() ? FullBalanceImg : EmptyBalanceImg} alt="balance_image" />
        <Text
          display="supperLarge"
          light
          style={{
            marginLeft: '15px',
            color: isBalanceFull() && theme.colors.bgNav,
          }}
        >
          {packageBalance.count || 0}
          <Text
            display="additionalMediumText"
            light
            style={{
              marginLeft: '5px',
              color: isBalanceFull() && theme.colors.bgNav,
            }}
          >
            {declOfNum(balanceCount || 0, [t('Lesson1'), t('Lesson2'), t('Lessons.Lessons')])}
          </Text>
        </Text>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link to={to}>
          {isBalanceFull() ? (
            <StudentButton
              onClick={goToPaymentPage}
              text={t('Balance.TopUp')}
              bgColor={theme.colors.white}
              textColor={theme.colors.bgNav}
              styles={{ border: '1px solid #4D6084' }}
            />
          ) : (
            <StudentButton
              onClick={goToPaymentPage}
              text={t('Balance.Start')}
              bgColor={theme.colors.button}
              img={StartImg}
            />
          )}
        </Link>
        <Styled.BonusPresent
          onClick={showPromoCodeModal}
          style={{ color: isBalanceFull() && theme.colors.bgNav }}
        >
          {t('Balance.Get2Lessons')}
        </Styled.BonusPresent>
      </div>
    </Styled.Container>
  )
}

export default Balance
