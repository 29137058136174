import React, { useState, useEffect, useCallback } from 'react'
import * as Styled from './balanceHistory.styles'
import DownArrow from '@assets/images/dropdown.png'
import { fetchStudentPayments } from '@servise'
import { authRef } from '@configs/firebase'
import { IPayment } from '@models'
import { useTranslation } from 'react-i18next'
import { CURRRENCY_SYMBOL, expiredDays, PACKAGE_TYPE, PACKAGES_DESC } from '@utils/constants'
import { capitalize } from '@utils/helperst'
import moment from 'moment'

const BalanceHistory = () => {
  const [payments, setPayments] = useState<Array<IPayment>>([])
  const [filteredPayments, setFilteredPayments] = useState<Array<IPayment>>([])
  const [activeFilter, setActiveFilter] = useState({ value: '', title: '' })
  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [expiredDay, setExpiredDay] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    const calculateBalance = paymentsArray => {
      return [...paymentsArray]
        .sort((a, b) => a.date.toDate() - b.date.toDate())
        .reduce((acc, payment, index) => {
          const balance = acc[index - 1]?.balance || 0
          return [...acc, { ...payment, balance: balance + payment.package }]
        }, [])
        .reverse()
    }

    const filtered = payments.filter(payment => {
      if (!payment) return false
      if (activeFilter.value === PACKAGE_TYPE.RUSSIAN) {
        return (
          (payment?.type !== PACKAGE_TYPE.HISPANIC &&
            payment.type !== PACKAGE_TYPE.FAMILY &&
            payment.type !== PACKAGE_TYPE.GROUP) ||
          !payment.type
        )
      }
      return payment.type === activeFilter.value
    })

    setFilteredPayments(calculateBalance(filtered))
  }, [activeFilter, payments])

  useEffect(() => {
    fetchStudentPayments(authRef.currentUser.uid).then(res => {
      const sortedPayments = res.sort((a, b) => b.date.toDate() - a.date.toDate())
      setPayments(sortedPayments)
    })
  }, [])

  useEffect(() => {
    const options = []
    const addedTypes = new Set()

    payments.forEach(item => {
      if (!item) return
      if (item.type === 'russian' || !item.type) {
        if (!addedTypes.has('russian')) {
          options.push({ value: 'russian', title: 'Package.Individual' })
          addedTypes.add('russian')
        }
      } else if (item.type === 'family') {
        if (!addedTypes.has('family')) {
          options.push({ value: 'family', title: 'Package.Family' })
          addedTypes.add('family')
        }
      } else if (item.type === 'hispanic') {
        if (!addedTypes.has('hispanic')) {
          options.push({ value: 'hispanic', title: 'Package.Hispanic' })
          addedTypes.add('hispanic')
        }
      } else if (item.type === 'group') {
        if (!addedTypes.has('group')) {
          options.push({ value: 'group', title: 'Package.Group' })
          addedTypes.add('group')
        }
      }
    })

    setOptions(options)
    if (!activeFilter.value && options.length > 0) {
      setActiveFilter(options[0])
    }
  }, [payments])

  const handleOption = value => {
    setActiveFilter(options.find(item => item.value === value))
  }

  useEffect(() => {
    if (activeFilter.value && payments.length > 0) {
      const lastActivePayment = payments.find(
        payment => payment.package > 0 && (payment.type === activeFilter.value || !payment.type)
      )
      if (lastActivePayment) {
        if (lastActivePayment.type === 'group') {
          const expiredDay = moment(lastActivePayment.date.toDate()).add(expiredDays[8], 'days')
          setExpiredDay(expiredDay)
        } else {
          const expiredDay = moment(lastActivePayment.date.toDate()).add(
            expiredDays[lastActivePayment.package],
            'days'
          )
          setExpiredDay(expiredDay)
        }
      } else {
        setExpiredDay(null)
      }
    }
  }, [activeFilter, payments])
  const makeDesc = useCallback((payment: IPayment): string => {
    if (payment.amount > 0) {
      let currency = payment.currency === undefined ? 'RUB' : payment.currency.toUpperCase()
      return `${t('Manager.Description.Topup')} + ${payment.amount.toLocaleString()} ${
        CURRRENCY_SYMBOL[currency]
      }  ${payment.type ? t(PACKAGES_DESC[payment.type].title) : ''}`
    }

    if (payment.package < 0)
      return `${capitalize(t('Class1'))}${
        payment.n ? `, ${t('Teacher').toLowerCase()}: ${payment.n}` : ''
      }`

    if (payment.amount === 0 && payment.package === 0) return t('Triallesson')

    return t('Bonus')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Styled.Wrapper>
      <Styled.ActivePackage>
        {options.length <= 1 ? (
          options.length === 1 ? (
            <span>{`${t('History.YourPackage')}"${t(activeFilter.title)}"`}</span>
          ) : (
            <></>
          )
        ) : (
          <>
            <span>{t('ChoosePackage')}</span>
            <Styled.ChoosenOption onClick={() => setIsOpen(!isOpen)}>
              {t(activeFilter.title)}
              <img src={DownArrow} alt="" />
              {isOpen && (
                <Styled.Options>
                  {options.map(item => (
                    <Styled.OptionItem
                      key={item.value}
                      onClick={() => {
                        handleOption(item.value)
                      }}
                    >
                      {t(item.title)}
                    </Styled.OptionItem>
                  ))}
                </Styled.Options>
              )}
            </Styled.ChoosenOption>
          </>
        )}
        <Styled.ExpiredBlock>
          {expiredDay && t('History.Expired', { date: expiredDay.format('DD.MM.YYYY') })}
        </Styled.ExpiredBlock>
      </Styled.ActivePackage>
      <Styled.BalanceHistory>
        <Styled.BalanceHead>
          <Styled.BalanceColumn>
            <th>{t('Date')}</th>
            <th>{t('Status')}</th>
            <th>{t('TheChange')}</th>
            <th>{t('Balance')}</th>
          </Styled.BalanceColumn>
        </Styled.BalanceHead>
        <Styled.BalanceBody>
          {filteredPayments.map(payment => {
            let date
            let time
            try {
              date = moment(payment.date?.seconds ? payment.date.toDate() : payment.date).format(
                'DD/MM/YYYY'
              )
              time = moment(payment.date?.seconds ? payment.date.toDate() : payment.date).format(
                'HH:mm'
              )
            } catch (e) {
              date = new Date(payment.date * 1000).toLocaleString()
            }
            return (
              <Styled.BalanceColumn
                key={`${date}-${payment.balance}-${Math.random()}`}
                isGreen={payment.amount > 0}
              >
                <td>
                  <Styled.DateColumn isGreen={payment.amount > 0}>{date}</Styled.DateColumn>
                  <Styled.TimeColumn>{time}</Styled.TimeColumn>
                </td>
                <td>{payment.desc ? t(payment.desc) : makeDesc(payment)}</td>
                <td>{payment.package > 0 ? `+${payment.package}` : payment.package}</td>
                <td>{payment.balance}</td>
              </Styled.BalanceColumn>
            )
          })}
        </Styled.BalanceBody>
      </Styled.BalanceHistory>
    </Styled.Wrapper>
  )
}

export default BalanceHistory
