import * as React from 'react'

const VolumeIcon = ({ isPlayed, mute, isAi = false }) => {
  if (mute) {
    return (
      <svg
        width="18"
        height="12"
        viewBox="0 0 18 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.57489 0.0740296C8.83474 0.19892 9 0.461703 9 0.750007V11.25C9 11.5383 8.83474 11.8011 8.57489 11.926C8.31504 12.0509 8.00661 12.0158 7.78148 11.8357L4.23691 9.00001H1.5C1.08579 9.00001 0.75 8.66422 0.75 8.25001V3.75001C0.75 3.33579 1.08579 3.00001 1.5 3.00001H4.23691L7.78148 0.164355C8.00661 -0.0157466 8.31504 -0.0508604 8.57489 0.0740296ZM7.5 2.31048L4.96852 4.33566C4.83554 4.44205 4.6703 4.50001 4.5 4.50001H2.25V7.50001H4.5C4.6703 7.50001 4.83554 7.55797 4.96852 7.66436L7.5 9.68954V2.31048Z"
          fill={'#7E92AC'}
        />
      </svg>
    )
  }
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57489 3.07403C8.83474 3.19892 9 3.4617 9 3.75001V14.25C9 14.5383 8.83474 14.8011 8.57489 14.926C8.31504 15.0509 8.00661 15.0158 7.78148 14.8357L4.23691 12H1.5C1.08579 12 0.75 11.6642 0.75 11.25V6.75001C0.75 6.33579 1.08579 6.00001 1.5 6.00001H4.23691L7.78148 3.16436C8.00661 2.98425 8.31504 2.94914 8.57489 3.07403ZM7.5 5.31048L4.96852 7.33566C4.83554 7.44205 4.6703 7.50001 4.5 7.50001H2.25V10.5H4.5C4.6703 10.5 4.83554 10.558 4.96852 10.6644L7.5 12.6895V5.31048Z"
        fill={isAi ? 'rgba(84, 130, 248, 1)' : 'rgba(255, 146, 45, 1)'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7722 3.16686C14.0652 2.87401 14.5401 2.87408 14.8329 3.16702C16.3795 4.71412 17.2484 6.81216 17.2484 8.99976C17.2484 11.1874 16.3795 13.2854 14.8329 14.8325C14.5401 15.1255 14.0652 15.1255 13.7722 14.8327C13.4793 14.5398 13.4792 14.065 13.7721 13.772C15.0375 12.5062 15.7484 10.7896 15.7484 8.99976C15.7484 7.20991 15.0375 5.49333 13.7721 4.22752C13.4792 3.93458 13.4793 3.4597 13.7722 3.16686ZM11.1247 5.81436C11.4177 5.52151 11.8926 5.52158 12.1854 5.81452C13.029 6.65839 13.5029 7.80278 13.5029 8.99602C13.5029 10.1893 13.029 11.3336 12.1854 12.1775C11.8926 12.4705 11.4177 12.4705 11.1247 12.1777C10.8318 11.8848 10.8317 11.41 11.1246 11.117C11.687 10.5544 12.0029 9.79151 12.0029 8.99602C12.0029 8.20052 11.687 7.4376 11.1246 6.87502C10.8317 6.58208 10.8318 6.1072 11.1247 5.81436Z"
        fill={isAi ? 'rgba(84, 130, 248, 1)' : 'rgba(255, 146, 45, 1)'}
      />
    </svg>
  )
}

export default VolumeIcon
