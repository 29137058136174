import styled from 'styled-components'

import { CustomLink, Text } from '../../components'
import { withStyles, Tooltip as MuiToolTip } from '@material-ui/core'
import StatisticsForTheYear from './StatisticsForTheYear/icons/for-button.svg'

export const Tooltip = withStyles({
  tooltip: {
    background: 'transparent',
  },
})(MuiToolTip)

export const TooltipContainer = styled.div`
  width: fit-content;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #ffffff;
  background: #414752;
  opacity: 0.8;
  border-radius: 8px;
  padding: 14px;
`

export const StudentPageContainer = styled.div`
  font-family: Open Sans;
  height: 100%;
  width: 100%;
  display: flex;
  background: linear-gradient(to right, #e1ecff 50%, #f3f5f9 50% 100%);
`

export const CenterContent = styled.div`
  height: 100%;
  max-width: 1440px;
  background: linear-gradient(to right, #e1ecff 20%, #f3f5f9 20% 100%);
  @media (min-width: 1440px) {
    width: 100%;
    margin: auto;
  }
  //@media (min-width: 1265px) {
  //  padding: 0 20px;
  //}
`

export const MainDashboard = styled.div`
  min-height: 775px;
  background-color: ${props => props.theme.colors.classBg};
  border-radius: ${props => props.theme.borderRadius.mainDashBoard};
  margin: 20px 0 0 0;
  display: flex;

  @media (max-width: 1470px) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`

export const DhMenu = styled.div`
  min-width: 310px;
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
export const DhContent = styled.div`
  max-width: 1160px;
  width: 100%;
  height: 100%;
`

export const PageContent = styled.div`
  padding: 40px;
`

/* Menu block */
export const MenuOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
`

export const MenuLink = styled(CustomLink)<{
  $active?: boolean
  $itemIsNew?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 ${props => props.theme.leftSidePadding};
  display: flex;
  color: ${props => (props.$active ? props.theme.textColors.active : props.theme.textColors.menu)};
  &:nth-child(2n) {
    margin: 15px 0;
  }
  > svg {
    fill: ${props => (props.$active ? props.theme.textColors.active : props.theme.textColors.menu)};
    margin-right: 16px;
  }
  &:hover {
    color: ${props => props.theme.textColors.active};
    opacity: 0.5;
    > svg {
      fill: ${props => props.theme.textColors.active};
    }
  }
  &:before {
    content: ' ';
    width: 5px;
    height: 100%;
    position: relative;
    right: 30px;
    padding: 2.5px 0;
    ${props => props.$active && `background-color: ` + props.theme.textColors.active}
  }

  &:after {
    content: '';
    ${props => props.$itemIsNew && `border: 5px solid orange;`}
    border-radius: 50%;
    position: relative;
    left: 5px;
  }
`

export const MenuItem = styled(Text)`
  display: flex;
  align-items: center;
  padding: 0 ${props => props.theme.leftSidePadding};
  display: flex;
  color: ${props => props.theme.textColors.menu};
  cursor: pointer;
  &:nth-child(2n) {
    margin: 15px 0;
  }
  > svg {
    fill: ${props => props.theme.textColors.menu};
    margin-right: 16px;
  }
  &:hover {
    color: ${props => props.theme.textColors.active};
    opacity: 0.5;
    > svg {
      fill: ${props => props.theme.textColors.active};
    }
  }
`

export const Counter = styled.div`
  margin-left: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  color: #ffffff;
  background: #ff922d;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ConnectionError = styled.div<{ isOnline: boolean }>`
  background: rgb(21, 86, 207);
  z-index: 555;
  color: white;
  text-align: center;
  position: fixed;
  bottom: ${props => (props.isOnline ? '-50px' : 0)};
  width: 100%;
  padding: 5px 0px;
  transition: all 0.6s;
`

export const StatisticsYearButton = styled.button`
  border-radius: 20px;
  padding: 22.5px 53px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  background: linear-gradient(90deg, #9ad9fc 0%, #81c1fd 21%, #4284ff 100%);
  width: fit-content;
  align-self: center;
  margin-top: 40px;
  position: relative;
  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: -15px;
    background: url(${StatisticsForTheYear}) no-repeat center center;
    background-size: contain;
    width: 76.52px;
    height: 85px;
  }
`
