import * as React from 'react'
import Dropdown from 'rc-dropdown'
import DownArrow from '@components/Icons/down_dropdown.svg'
import * as Styled from './dropdown.styles'

interface Props {
  chosenOption: { title: string; id: string }
  setOption: (value: { title: string; id: string }) => void
  options: { title: string; id: string }[]
}

const CustomDropDown = ({ chosenOption, setOption, options }: Props) => {
  const [open, setOpen] = React.useState(false)

  const handleOptionClick = (option: { title: string; id: string }) => {
    setOption(option)
    setOpen(!open)
  }

  const handleClickAway = () => {
    setOpen(false)
  }

  return (
    <Dropdown
      trigger="click"
      animation="slide-up"
      overlayStyle={{ top: -10 }}
      overlay={
        <Styled.Options data-testid="options">
          {options.map(option => (
            <Styled.OptionItem key={option.id} onClick={() => handleOptionClick(option)}>
              {option.title}
            </Styled.OptionItem>
          ))}
        </Styled.Options>
      }
    >
      <Styled.ChosenOption onClick={() => setOpen(prev => !prev)}>
        {chosenOption.title}
        <img src={DownArrow || ''} alt="" />
      </Styled.ChosenOption>
    </Dropdown>
  )
}

export default CustomDropDown
