import React, { useMemo, useState } from 'react'
import * as Styled from './teachersInfo.styles'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button } from '@components'
import { IProfile } from '@models/IProfile'
import { notifyPotentialStudentBookedTime } from '@servise/webhookAPI'
import SuccessBull from '@images/success_registration_bull.svg'

interface IBookinTeacherModal {
  teacher: IProfile
  handleCloseBooking: () => void
}

const BookinTeacherModal = ({ teacher, handleCloseBooking }: IBookinTeacherModal) => {
  const [isSend, setIsSend] = useState(false)
  const { t } = useTranslation()
  const ValidateSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string()
      .email()
      .required(),
    phone: Yup.string()
      .matches(/^[0-9]{10,15}$/, t('CheckNumber'))
      .required(),
  })
  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
    },
    validationSchema: ValidateSchema,
    onSubmit: async values => {
      try {
        const { name, phone, email } = values
        await notifyPotentialStudentBookedTime(name, phone, email, teacher.name, teacher.lastName)
        setIsSend(true)
      } catch {
        toast(t('Error.Request'), {
          autoClose: 4000,
          position: 'bottom-center',
          closeButton: false,
          hideProgressBar: true,
          className: 'student-message-failed',
        })
      }
    },
  })

  const isError = useMemo(() => {
    if (formik.errors.email || formik.errors.name || formik.errors.phone) {
      return true
    }
    return false
  }, [formik.errors])
  return (
    <Styled.BookinTeacherModalEl>
      {!isSend ? (
        <>
          {' '}
          <Styled.Title>{t('BookingInfoTitle')}</Styled.Title>
          <Styled.Form onSubmit={formik.handleSubmit}>
            <Styled.Input
              placeholder={t('Input.Name')}
              value={formik.values.name}
              onChange={formik.handleChange}
              name="name"
              required
            />
            <Styled.Input
              placeholder={t('PhoneNumber')}
              value={formik.values.phone}
              onChange={formik.handleChange}
              name="phone"
              required
            />
            <Styled.Input
              placeholder={t('TeacherEmail')}
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
              required
            />
            <Button
              customStyles={{
                background: 'linear-gradient(270.29deg, #FF922D 18.37%, #FFB800 99.74%)',
              }}
              children={t('HomeWork.Send')}
              type="submit"
              disabled={
                isError ||
                formik.values.email === '' ||
                formik.values.name === '' ||
                formik.values.phone === ''
              }
            />
          </Styled.Form>
        </>
      ) : (
        <>
          <img src={SuccessBull} alt="bull" className="bull_img" />
          <Styled.SuccessTitle>{t('Success.Request')}</Styled.SuccessTitle>
          <Styled.SuccessSubtitle>
            <span>{t('Manager.Soon')}</span>
            <span>{t('TryBot')}</span>
          </Styled.SuccessSubtitle>
          <Button
            children={
              <a
                href="https://t.me/escuela_daily_bot?start=137732"
                target="_blank"
                rel="noreferrer"
              >
                {t('Balance.Start')}
              </a>
            }
            customStyles={{
              background: 'linear-gradient(270.29deg, #FF922D 18.37%, #FFB800 99.74%)',
              alignSelf: 'center',
            }}
            onClick={handleCloseBooking}
          />
        </>
      )}
    </Styled.BookinTeacherModalEl>
  )
}

export default BookinTeacherModal
