import React from 'react'
import MicroModal from 'react-micro-modal'

import './modal.scss'

function MainModal(props) {
  const { ...rest } = props

  return (
    <MicroModal
      {...rest}
      modalClassName="micro-modal custom-modal-class"
      modalOverlayClassName="custom-modal-class"
      modalOverlayStyles={{
        background: 'rgba(48, 72, 89, 0.73)',
        zIndex: 1001,
      }}
      containerStyles={{
        boxShadow: props.boxShadow ? props.boxShadow : '0px 6px 18px rgba(0, 0, 0, 0.06)',
        backgroundColor: props.backgroundColor ? props.backgroundColor : '#fff',
        borderRadius: props.borderRadius ? props.borderRadius : '16px',
        padding: '',
        width: 'fit-content',
        maxWidth: props.maxWidth ? props.maxWidth : props.max ? '810px' : '555px',
        maxHeight: props.maxHeight ? props.maxHeight : 'fit-content',
        overflowY: 'auto',
      }}
    />
  )
}

MainModal.defaultProps = {
  open: false,
  closeOnOverlayClick: true,
  closeOnEscapePress: true,
  max: false,
}

export default MainModal
