import styled from 'styled-components'

export const Package = styled.div``

export const PackageTitle = styled.p`
  color: rgba(65, 71, 82, 1);
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Info = styled.img`
  margin-left: 0.4vw;
`

export const Desc = styled.span`
  font-family: 'Open Sans';
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
`

export const CoursesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: auto;
  gap: 20px;
  padding: 15px 0 20px;
  ::-webkit-scrollbar {
    height: 7px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.scrollTrack};
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 2px;
  }
`
