import React, { useMemo } from 'react'
import * as Styled from './promoCode.styles'
import Check from '@images/round_check.svg'
import Loader from '@images/promoCodeLoader.gif'
import Cancel from '@images/round_cancel.svg'
import { useTranslation } from 'react-i18next'
import GiftIcon from '../Icons/GiftIcon'

interface Props {
  promoCode: string
  allAccess?: boolean
  discount?: number
  isMiniCourse?: boolean
  freeMinicourses?: string
  discountedMinicourses?: string
  handleChangePromoCode: (e: React.ChangeEvent<HTMLInputElement>) => void
  suspensePromoCode: string
  showPromoCodeModal: () => void
}

const PromoCode = ({
  promoCode,
  allAccess,
  discount,
  freeMinicourses,
  discountedMinicourses,
  handleChangePromoCode,
  suspensePromoCode,
  showPromoCodeModal,
  isMiniCourse,
}: Props) => {
  const { t } = useTranslation()

  let suspenseComponent = useMemo(() => {
    if (suspensePromoCode === 'pending') {
      return (
        <Styled.SuspenseContainer>
          <Styled.PromoCodeStatus status={suspensePromoCode}>
            <Styled.Loader src={Loader} /> {t('PromoCode.Checking')}
          </Styled.PromoCodeStatus>
        </Styled.SuspenseContainer>
      )
    } else if (suspensePromoCode === 'success' && !isMiniCourse) {
      return (
        <Styled.SuspenseContainer>
          <Styled.PromoCodeStatus status={suspensePromoCode}>
            <Styled.Check src={Check} /> {t('PromoCode.Success')}
          </Styled.PromoCodeStatus>
        </Styled.SuspenseContainer>
      )
    } else if (suspensePromoCode === 'success' && allAccess) {
      return (
        <Styled.SuspenseContainer>
          <Styled.PromoCodeStatus status={suspensePromoCode}>
            <Styled.Check src={Check} />
            {t('PromoCode.SuccessAll', {
              discount: discount,
            })}
          </Styled.PromoCodeStatus>
        </Styled.SuspenseContainer>
      )
    } else if (suspensePromoCode === 'success' && freeMinicourses?.length > 0) {
      return (
        <Styled.SuspenseContainer>
          <Styled.PromoCodeStatus status={suspensePromoCode}>
            <Styled.Check src={Check} />
            {t('PromoCode.SuccessFree', {
              freeMinicourses: freeMinicourses,
            })}
          </Styled.PromoCodeStatus>
        </Styled.SuspenseContainer>
      )
    } else if (suspensePromoCode === 'success' && discountedMinicourses?.length > 0) {
      return (
        <Styled.SuspenseContainer>
          <Styled.PromoCodeStatus status={suspensePromoCode}>
            <Styled.Check src={Check} />
            {t('PromoCode.SuccessCertain', {
              disCourses: discountedMinicourses,
              discount: discount,
            })}
          </Styled.PromoCodeStatus>
        </Styled.SuspenseContainer>
      )
    } else if (suspensePromoCode === 'fail') {
      return (
        <Styled.SuspenseContainer>
          <Styled.PromoCodeStatus status={suspensePromoCode}>
            {t('PromoCode.Fail')}
          </Styled.PromoCodeStatus>
        </Styled.SuspenseContainer>
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeMinicourses, suspensePromoCode, discount, discountedMinicourses, isMiniCourse])

  return (
    <Styled.PromoCodeContainer>
      <Styled.PromoCodeInputContainer isMini={isMiniCourse}>
        <Styled.PromoCode
          value={promoCode}
          onChange={handleChangePromoCode}
          type="search"
          placeholder={t('PromoCode.Enter')}
          CancelImg={Cancel}
          status={suspensePromoCode}
          promocodeLength={promoCode.length}
          maxLength={18}
        />
        {promoCode.length !== 0 && suspenseComponent}
        {isMiniCourse && <GiftIcon />}
      </Styled.PromoCodeInputContainer>
      {!isMiniCourse && (
        <Styled.Container>
          <GiftIcon />
          <Styled.PromoText>{t('PromoCode.PromoText')}</Styled.PromoText>
          <Styled.PromoButton onClick={showPromoCodeModal}>{t('Invite')}</Styled.PromoButton>
        </Styled.Container>
      )}
    </Styled.PromoCodeContainer>
  )
}

export default PromoCode
