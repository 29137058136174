import { logger } from '@utils/ConsoleLogger'
import { Dispatch } from 'redux'
import firebase from 'firebase/app'
import moment from 'moment'

import {
  classroomsRef,
  database as db,
  usersRef,
  authRef,
  studentsRef,
  publishedCoursesRef,
  activeCoursesRef,
  firestoreRef,
  firebaseTimestamp,
  courseRef,
  recordsRef,
} from '@configs/firebase'
import { API_PATHS, getRateLesson, getTeacherRatesInfo, TZ_MSK } from '@utils/constants'
import { rand, patterns } from '@utils/classesHelpers'

import { ClassroomActionsTypes } from '@store/classroom/types'
import {
  addFileToClassroomAction,
  createClassroomAction,
  deleteClassroomAction,
  setClassroomAction,
  setClassroomFilesAction,
  setClassroomsAction,
  updateClassroomAction,
  setStudents,
  setStudent,
  setPublishedCoursesAction,
  setDisplayedCourseAction,
  setStudentDataInClassroomAction,
  removeStudent as removeStudentAction,
} from '@store/classroom/actions'

import {
  IClassroom,
  IClassroomBlock,
  IDocumentState,
  IProfile,
  IPublishedCourse,
  IStudentCurrentCourse,
  ICurrentCourseInfo,
  IStudentProfile,
  ILessonRealTimeData,
  ITeacherCourseNotesUpdates,
  ILessonRealTimeDataNotebookState,
  ILessonNotebookProps,
  ILesson,
  IPublishedCourseLesson,
  IPlayerState,
  ILessonHomeworkAnswers,
  HomeworkStatuses,
  IPassedLesson,
  PassedLessonStatus,
  IPayment,
  MessageType,
  IRecord,
} from '@models'
import {
  sendMessageCountingLessons,
  sendMessageStartTrialCourseLesson,
  sendMessageStartTrialLesson,
} from './webhookAPI'
import {
  fetchStudentProfileByUid,
  fetchStudentPayments,
  fetchTeacherProfileByUid,
} from './profileAPI'
import { TranslationLangs } from './vocabulary'
import {
  changeInterestsKeys,
  changeGoalsKeys,
  getPassedLessonsTeacherByStudent,
} from './personalAPI'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { chatAPI } from './chatAPI'
import { s } from '@fullcalendar/core/internal-common'

export const createClassroom = (classroomData: IClassroom, student: IStudentProfile) => {
  return async (dispatch: Dispatch<ClassroomActionsTypes>) => {
    try {
      if (classroomData.id) {
        const cloneClassInfo = await classroomsRef.add({
          ...classroomData,
          d: firebaseTimestamp.now(),
        })
        let files = await classroomsRef
          .doc(classroomData.id)
          .collection('files')
          .orderBy('position', 'asc')
          .get()

        await Promise.all(
          files.docs.map(async doc => {
            await classroomsRef
              .doc(cloneClassInfo.id)
              .collection('files')
              .add(doc.data())
          })
        )

        dispatch(
          createClassroomAction({
            ...classroomData,
            notes: '',
            notebookText: '',
            student,
            id: cloneClassInfo.id,
          })
        )

        return cloneClassInfo
      } else {
        const classInfo = await classroomsRef.add({
          ...classroomData,
          d: firebaseTimestamp.now(),
        })

        dispatch(
          createClassroomAction({
            ...classroomData,
            student,
            id: classInfo.id,
          })
        )

        return classInfo
      }
    } catch (error) {
      logger.error(error)
    }
  }
}

export const getClassroomFiles = (classID: string) => {
  return async (dispatch: Dispatch<ClassroomActionsTypes>) => {
    classroomsRef
      .doc(classID)
      .collection('files')
      .onSnapshot(snapshot => {
        if (snapshot.docs.length && !snapshot.docs.every(doc => doc.data().position)) {
          snapshot.docs.map(async (doc, index) => {
            await classroomsRef
              .doc(classID)
              .collection('files')
              .doc(doc.id)
              .update({
                position: index + 1,
              })
          })
        } else {
          let fileList = snapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
          })) as IClassroomBlock[]
          fileList = fileList.sort((a, b) => a.position - b.position)
          dispatch(setClassroomFilesAction(fileList))
        }
      })
  }
}

export const getStudentClassRoomsCount = async () => {
  const uid: string = authRef.currentUser.uid

  const snapshot = await classroomsRef
    .where('student_uid', '==', uid)
    .orderBy('d', 'asc')
    .get()

  return snapshot.docs.length
}

export const getClassrooms = (uid: string, activeTab: string) => {
  return async (dispatch: Dispatch<ClassroomActionsTypes>) => {
    const snapshot = await classroomsRef.where('uid', '==', uid).get()

    let classrooms: IClassroom[] = []

    activeTab &&
      activeTab !== 'createClass' &&
      activeTab !== 'allCourses' &&
      (await Promise.all(
        snapshot.docs.map(async classroom => {
          if (classroom.data().student_uid === activeTab) {
            let student = await fetchStudentProfileByUid(classroom.data().student_uid)
            classrooms.push({
              ...classroom.data(),
              student: student,
              id: classroom.id,
            } as IClassroom)
          }
        })
      ))

    dispatch(setClassroomsAction(classrooms))
  }
}

export const sortClassrooms = (classrooms: IClassroom[]) => {
  const filterClassrooms = classrooms.filter(item => !item.d)
  return classrooms
    .filter(item => !!item.d)
    .sort((a, b) => new Date(b.d.toDate()).getTime() - new Date(a.d.toDate()).getTime())
    .concat(filterClassrooms)
}

export const getStudentClassrooms = (studentUid: string) => {
  return async (dispatch: Dispatch<ClassroomActionsTypes>) => {
    const snapshot = await classroomsRef.where('student_uid', '==', studentUid).get()
    const classrooms = snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    })) as IClassroom[]

    await Promise.all(
      classrooms.map(async cr => {
        cr.teacher = await usersRef
          .doc(cr.uid)
          .get()
          .then(doc => doc.data())
      })
    )

    dispatch(setClassroomsAction(classrooms))
  }
}

export const getClassroom = (classID: string, isStudent: any) => {
  return async (dispatch: Dispatch<ClassroomActionsTypes>) => {
    const snapshot = await classroomsRef.doc(classID).get()
    const classroomData = snapshot.data() as IClassroom

    if (classroomData) {
      if (!isStudent) {
        if (classroomData.uid !== authRef.currentUser.uid) {
          throw { code: 401 }
        } else {
          dispatch(setClassroomAction({ ...classroomData, id: snapshot.id }))
        }
      } else {
        dispatch(setClassroomAction({ ...classroomData, id: snapshot.id }))
      }
    } else {
      throw { code: 401 }
    }
    return classroomData
  }
}

export const addFileToClassroom = (fileData: IClassroomBlock, classID: string) => {
  return async (dispatch: Dispatch<ClassroomActionsTypes>) => {
    let maxPosition = 0
    let position = await classroomsRef
      .doc(classID)
      .collection('files')
      .orderBy('position', 'desc')
      .limit(1)
      .get()

    maxPosition =
      position?.docs?.map(res => {
        return res.data()
      })[0]?.position ?? 0

    let files = await classroomsRef
      .doc(classID)
      .collection('files')
      .orderBy('created_at', 'asc')
      .get()

    if (position.empty || position.docs.length !== files.docs.length) {
      files.docs.map(async (res, index) => {
        await classroomsRef
          .doc(classID)
          .collection('files')
          .doc(res.id)
          .update({
            position: index + 1,
          })
      })
      maxPosition = files?.docs?.length ?? 0

      position = await classroomsRef
        .doc(classID)
        .collection('files')
        .orderBy('position', 'desc')
        .limit(1)
        .get()
    }
    const fileInfo = await classroomsRef
      .doc(classID)
      .collection('files')
      .add({ ...fileData, position: maxPosition + 1 })
    dispatch(
      addFileToClassroomAction({
        ...fileData,
        id: fileInfo.id,
        position: maxPosition + 1,
      })
    )
  }
}

export const deleteFileFromClassroom = (classID: string, fileData: IClassroomBlock) => {
  return classroomsRef
    .doc(classID)
    .collection('files')
    .doc(fileData.id)
    .delete()
}

export const updateClassroom = (classroomData: IClassroom, student: IStudentProfile) => {
  return async (dispatch: Dispatch<ClassroomActionsTypes>) => {
    await classroomsRef.doc(classroomData.id).update({
      name: classroomData.name,
      description: classroomData.description,
      student_uid: classroomData.student_uid,
      img: classroomData.img,
    })
    dispatch(updateClassroomAction({ ...classroomData, student }))
  }
}

export const changeBlockPosition = (classId: string, fileID: string, upSide: boolean) => {
  return async (dispatch: Dispatch<ClassroomActionsTypes>) => {
    const files = await classroomsRef
      .doc(classId)
      .collection('files')
      .orderBy('position', 'asc')
      .get()
    const fileData: IClassroomBlock[] = files.docs.map(res => {
      return { id: res.id, ...res.data() }
    })
    const currentIndex = fileData.findIndex(file => file.id === fileID)

    if (upSide) {
      if (currentIndex !== 0) {
        fileData[currentIndex].position = fileData[currentIndex].position - 1
        fileData[currentIndex - 1].position = fileData[currentIndex - 1].position + 1
        dispatch(setClassroomFilesAction(fileData))
        fileData.map(async file => {
          await classroomsRef
            .doc(classId)
            .collection('files')
            .doc(file.id)
            .set({ ...file })
        })
      } else {
        return
      }
    } else {
      if (currentIndex !== fileData.length - 1) {
        fileData[currentIndex].position = fileData[currentIndex].position + 1
        fileData[currentIndex + 1].position = fileData[currentIndex + 1].position - 1
        dispatch(setClassroomFilesAction(fileData))
        fileData.map(async file => {
          await classroomsRef
            .doc(classId)
            .collection('files')
            .doc(file.id)
            .set({ ...file })
        })
      } else {
        return
      }
    }
  }
}

export const lockBlock = async (classId: string, fileId: string, isLocked: boolean) => {
  try {
    await classroomsRef
      .doc(classId)
      .collection('files')
      .doc(fileId)
      .update({ isLocked })
  } catch (error) {
    logger.error(`Failed to update lock status for file ${fileId}`, error)
    throw error
  }
}

export const deleteClassroom = (id: string) => {
  return async (dispatch: Dispatch<ClassroomActionsTypes>) => {
    let files = await classroomsRef
      .doc(id)
      .collection('files')
      .get()

    await Promise.all(
      files.docs.map(async doc => {
        await classroomsRef
          .doc(id)
          .collection('files')
          .doc(doc.id)
          .delete()
      })
    )

    await classroomsRef.doc(id).delete()
    db.ref(id).remove()

    dispatch(deleteClassroomAction(id))
  }
}

export const removeStudent = (studentId: string) => {
  return async (dispatch: Dispatch<ClassroomActionsTypes>) => {
    try {
      await usersRef.doc(authRef.currentUser.uid).update({
        students: firebase.firestore.FieldValue.arrayRemove(studentId),
      })
      let teacherEventsSnapshot = await usersRef
        .doc(authRef.currentUser.uid)
        .collection('events')
        .where('studentId', '==', studentId)
        .get()
      if (!teacherEventsSnapshot.empty) {
        teacherEventsSnapshot.forEach(doc => {
          doc.ref.delete()
        })
      }
      let studentEventsSnapshot = await studentsRef
        .doc(studentId)
        .collection('events')
        .where('teacherId', '==', authRef.currentUser.uid)
        .get()
      if (!studentEventsSnapshot.empty) {
        studentEventsSnapshot.forEach(doc => {
          doc.ref.delete()
        })
      }
      dispatch(removeStudentAction(studentId))
    } catch (error) {
      throw error
    }
  }
}

export type TypeChangeNoteBook = {
  value: string
  scrollPosition: any
  classID: string
  user: string | null
}

export type TypeChangeTranslatedWord = {
  word: string
  result: string
  sourceTextLang: TranslationLangs | ''
  classID: string
}

export const changeNotebookText = (data: TypeChangeNoteBook) => {
  const { classID, user, value, scrollPosition } = data
  const scroll = scrollPosition !== undefined ? { scrollPosition } : {}
  db.ref(`${classID}`)
    .child('notebookState')
    .set({
      text: value,
      userTyping: user,
      ...scroll,
    })
}

export const translatedWordChange = ({
  classID,
  word,
  result,
  sourceTextLang,
}: TypeChangeTranslatedWord) => {
  db.ref(`${classID}`)
    .child(`twState`)
    .set({
      w: word,
      r: result,
      stl: sourceTextLang,
    })
}

export type TypeChangeNotes = {
  value: string
  classID: string
}
export const changeNotes = (data: TypeChangeNotes) => {
  classroomsRef.doc(data.classID).update({
    notes: data.value,
  })
}

export type TypeChangePage = {
  useInput: boolean
  offset: number
  fileID: string
  numPages: number
  pageNumber: number
  classID: string
  documentState: IDocumentState
}

export const documentChangePage = (data: TypeChangePage) => {
  const { fileID, useInput, offset, numPages, pageNumber, classID, documentState } = data

  var newPageNumber = useInput ? offset : pageNumber + offset
  newPageNumber = newPageNumber ? newPageNumber : offset
  let pageNumbers = documentState.pageNumbers
  pageNumbers[fileID] = newPageNumber

  if (numPages > 0) {
    db.ref(`${classID}`)
      .child('documentState')
      .set({
        pageNumbers,
      })
  }
}

export type TypePositionChanged = {
  width: number
  height: number
  position: Position
  classID: string
  fileID: string
}

export type Position = {
  x: number
  y: number
}

export const cursorPositionChanged = (data: TypePositionChanged) => {
  const { classID, position, width, height, fileID } = data
  db.ref(`${classID}`)
    .child('cursorState')
    .set({
      position: {
        x: position.x / width,
        y: position.y / height,
      },
      fileID,
    })
}

export type TypeDisconnect = {
  isStudent: boolean
  classID: string
}

export const updateOnDisconnectUser = (data: TypeDisconnect) => {
  const { isStudent, classID } = data

  if (isStudent) {
    db.ref(`${classID}/user`)
      .onDisconnect()
      .update({
        isMakeCall: false,
        studentIsOnline: false,
      })

    db.ref(`${classID}/user`).update({
      studentIsOnline: true,
    })
  } else {
    db.ref(`${classID}/user`)
      .onDisconnect()
      .update({
        teacherIsOnline: false,
      })

    db.ref(`${classID}/user`).update({
      teacherIsOnline: true,
    })
  }
}

export const hiddenFile = (fileData: IClassroomBlock, classID: string) => {
  const isHidden = fileData.isHidden === undefined ? false : fileData.isHidden
  classroomsRef
    .doc(classID)
    .collection('files')
    .doc(fileData.id)
    .update({ isHidden: !isHidden })
}

export const getStudentsInformation = (studentIds: string[] | null) => async (
  dispatch: Dispatch<ClassroomActionsTypes>
) => {
  let students: IStudentProfile[] = await fetchStudents(studentIds)

  dispatch(setStudents(students))
}

export const getStudentDataInClassroom = (studentId: string) => async (
  dispatch: Dispatch<ClassroomActionsTypes>
) => {
  let studentData: IStudentProfile = await fetchStudentProfileByUid(studentId)
  const payments = await fetchStudentPayments(studentId)

  dispatch(
    setStudentDataInClassroomAction({
      ...studentData,
      payments,
      passedLessons: studentData.passedLessons || 0,
      id: studentId,
    })
  )
}

export const fetchStudents = async (studentIds: string[] | null) => {
  let students: IStudentProfile[] = []
  if (studentIds) {
    const snapshots = await Promise.all(studentIds.map(id => studentsRef.doc(id).get()))
    for (let s of snapshots) {
      const data = s.data()
      if (data && data.student !== undefined) {
        const interests = changeInterestsKeys(data.interests || [])
        const goals = changeGoalsKeys(data.goals || [])

        students.push(({
          ...data,
          interests,
          goals,
          id: s.id,
        } as unknown) as IStudentProfile)
      }
    }
  } else {
    const snapshot = await studentsRef.where('student', '!=', null).get()

    snapshot.docs.forEach(s =>
      s.data().student !== undefined
        ? students.push({ ...s.data(), id: s.id } as IStudentProfile)
        : null
    )
  }
  return students
}

export const getStudentCurrentCourse = async ({
  studentId,
  courseId,
}): Promise<ICurrentCourseInfo> => {
  const studentCourseRef = studentsRef
    .doc(studentId)
    .collection('courses')
    .doc(courseId)

  const studentCurrentCourseSnapshot = await studentCourseRef.get()
  const publishedCourseSnaps = await publishedCoursesRef.doc(courseId).get()

  const {
    currentLessonId,
    lessonIds,
    showLessonIds,
  } = studentCurrentCourseSnapshot.data() as IStudentCurrentCourse
  const { name, lessons, progress } = publishedCourseSnaps.data() as IPublishedCourse

  const studentLessons = lessonIds
    ? await Promise.all(
        lessonIds?.map(async id => {
          const lessonSnap = await studentCourseRef
            .collection('lessons')
            .doc(id)
            .get()
          const { name, description, image, order } = lessonSnap.data()
          return {
            id: lessonSnap.id,
            name,
            description,
            image,
            order,
          }
        })
      )
    : []

  return {
    name,
    id: publishedCourseSnaps.id,
    progress: progress || [],
    lessons: lessons
      .sort((a, b) => {
        return a.order - b.order
      })
      .map(l => {
        const isStarted = lessonIds?.includes(l.id)
        const lesson = isStarted ? studentLessons.find(sl => sl.id === l.id) : l
        return {
          ...lesson,
          image: lesson.image ?? patterns[rand(0, 2)],
          isCompleted: lesson.id !== currentLessonId && isStarted,
          isCurrent: lesson.id === currentLessonId,
          isVisible: showLessonIds?.includes(l.id),
        }
      }),
  }
}
export const getStudentInformation = (studentId: string) => async (
  dispatch: Dispatch<ClassroomActionsTypes>
) => {
  const studentSnapshot = await studentsRef.doc(studentId).get()
  const data = studentSnapshot.data()
  if (data?.currentCourseId) {
    data.currentCourse = await getStudentCurrentCourse({
      studentId: studentSnapshot.id,
      courseId: data.currentCourseId,
    })
  }

  dispatch(setStudent({ ...data, id: studentSnapshot.id } as IProfile))
}
export const changeVisibilty = (
  studentId: string,
  courseId: string,
  lessonId: string,
  isVisible: boolean
) => async (dispatch: Dispatch<ClassroomActionsTypes>) => {
  try {
    studentsRef.doc(`${studentId}/courses/${courseId}`).update({
      showLessonIds: isVisible
        ? firebase.firestore.FieldValue.arrayUnion(lessonId)
        : firebase.firestore.FieldValue.arrayRemove(lessonId),
    })

    await dispatch(getStudentInformation(studentId))
  } catch (e) {}
}
export const getPublishedCourses = (isMini?: boolean) => async (
  dispatch: Dispatch<ClassroomActionsTypes>
) => {
  const publishedCoursesSnapshot = await publishedCoursesRef.get()
  let publishedCourses = publishedCoursesSnapshot.docs.map(doc => {
    const course = doc.data()

    return {
      ...course,
      image: course.image ?? patterns[rand(0, 2)],
      lessons: course.lessons.map(l => ({
        ...l,
        image: l.image ?? patterns[rand(0, 2)],
      })),
      id: doc.id,
    } as IPublishedCourse
  })

  if (isMini) {
    const miniCoursesSnap = await studentsRef
      .doc(authRef.currentUser.uid)
      .collection('miniCourses')
      .get()

    const studentMiniCourses = await Promise.all(
      miniCoursesSnap.docs.map(async doc => {
        const course = doc.data()
        const lessonsSnap = await doc.ref.collection('lessons').get()
        const lessons: any[] = lessonsSnap.docs.map(doc => {
          const lesson = doc.data()

          return {
            ...lesson,
            image: lesson.image ?? patterns[rand(0, 2)],
            id: doc.id,
          }
        })

        const isPassedCourse =
          lessons.filter(item => item.status === HomeworkStatuses.DONE).length === lessons.length

        return ({
          ...course,
          progress: getMiniCourseProgress(lessons),
          isPassedCourse,
          image: course.image ?? patterns[rand(0, 2)],
          lessons,
          id: doc.id,
        } as unknown) as IPublishedCourse
      })
    )

    publishedCourses = publishedCourses
      .filter(item => item.isMini)
      .map(item => {
        const find = studentMiniCourses.find(miniCourseItem => miniCourseItem.id === item.id)

        if (find) {
          const isUpdated =
            moment(find.updatedAt?.toDate()).format('YYYY-MM-DD HH:mm') !==
            moment(item.updatedAt?.toDate()).format('YYYY-MM-DD HH:mm')

          let data = { ...find }
          if (isUpdated) {
            data = {
              ...find,
              ...item,
              progress: getMiniCourseProgress(item.lessons),
            }
          }
          return { ...data } as IPublishedCourse
        }
        return { ...item } as IPublishedCourse
      })
  } else {
    publishedCourses = publishedCourses.filter(item => !item.isMini)
  }

  dispatch(setPublishedCoursesAction(publishedCourses))
}

export const getPublishedCourse = (courseId: string, isMini?: boolean) => async (
  dispatch: Dispatch<ClassroomActionsTypes>
) => {
  try {
    if (!isMini) {
      const courseSnapshot = await publishedCoursesRef.doc(courseId).get()
      dispatch(
        setDisplayedCourseAction({
          ...courseSnapshot.data(),
          id: courseSnapshot.id,
          image: courseSnapshot.data().image ?? patterns[rand(0, 2)],
          lessons: courseSnapshot.data().lessons.map(l => ({
            ...l,
            image: l.image ?? patterns[rand(0, 2)],
          })),
        } as IPublishedCourse)
      )
      return
    }
    const [studentMiniCourse, publishedMiniCourse] = await Promise.all([
      fetchStudentMiniCourse(courseId),
      fetchPublishedCourse(courseId),
    ])
    const courseLessons = await fetchCourseLessons(courseId)
    let lessons = []
    let course: any = {}
    let isCopied = false

    if (studentMiniCourse) {
      isCopied = true
      const isUpdated =
        moment(studentMiniCourse.data.updatedAt?.toDate()).format('YYYY-MM-DD HH:mm') !==
        moment(publishedMiniCourse.data.updatedAt?.toDate()).format('YYYY-MM-DD HH:mm')

      course = {
        ...studentMiniCourse.data,
        id: courseId,
      }
      if (isUpdated) {
        await saveStudentMiniCourse(courseId, {
          ...studentMiniCourse.data,
          ...publishedMiniCourse.data,
        })
        course = {
          ...studentMiniCourse.data,
          ...publishedMiniCourse.data,
          id: courseId,
        }
        lessons = await saveStudentMiniCourseLessons(courseLessons, courseId)
      } else {
        const studentCourseLessons = await fetchStudentMiniCourseLessons(courseId)
        lessons = studentCourseLessons.map(doc => {
          const lesson = doc.data
          return {
            ...lesson,
            image: lesson.image ?? patterns[rand(0, 2)],
            id: doc.id,
          }
        })
      }
    } else {
      course = { ...publishedMiniCourse.data, id: courseId }
      lessons = publishedMiniCourse.data.lessons.map(lesson => {
        return {
          ...lesson,
          image: lesson.image ?? patterns[rand(0, 2)],
        }
      })
    }

    const isPassedCourse =
      lessons.filter(item => item.status === HomeworkStatuses.DONE).length === lessons.length

    dispatch(
      setDisplayedCourseAction(({
        ...course,
        progress: isCopied ? getMiniCourseProgress(lessons) : undefined,
        isPassedCourse,
        image: course.image ?? patterns[rand(0, 2)],
        lessons,
      } as unknown) as IPublishedCourse)
    )
  } catch (e) {
    logger.error('getPublishedCourse', e)
  }
}
export const saveStudentMiniCourse = async (courseId: string, courseData: any) => {
  let data = { ...courseData }

  delete data?.students

  await studentsRef
    .doc(authRef.currentUser.uid)
    .collection('miniCourses')
    .doc(courseId)
    .set(data)
}

export const saveStudentMiniCourseLessons = async (courseLessons: any[], courseId: string) => {
  const lessons = await Promise.all(
    courseLessons.map(async doc => {
      const prevLesson = await fetchMiniCourseLesson(courseId, doc.id)

      if (prevLesson && Object.values(prevLesson?.answers?.progress).filter(p => p).length > 0) {
        return prevLesson
      }

      const lessonData = doc.data
      const lesson = {
        ...lessonData,
        createdAt: firebaseTimestamp.now(),
        status: HomeworkStatuses.NOT_READY,
        answers: {
          pageStates: lessonData.pages.reduce((acc, { id }) => {
            return {
              ...acc,
            }
          }, {}),
          progress: lessonData.pages.reduce(
            (acc, { id }) => ({
              ...acc,
              [`pageId-${id}`]: false,
            }),
            {}
          ),
        },
      }

      await saveStudentMiniCourseLesson(courseId, doc.id, lesson)
      return {
        ...lesson,
        image: lesson.image ?? patterns[rand(0, 2)],
        id: doc.id,
      }
    })
  )
  return lessons
}

export const saveStudentMiniCourseLesson = async (
  courseId: string,
  lessonId: string,
  lesson: any
) => {
  try {
    await studentsRef
      .doc(authRef.currentUser.uid)
      .collection('miniCourses')
      .doc(courseId)
      .collection('lessons')
      .doc(lessonId)
      .set(lesson)
  } catch (e) {
    logger.error(e)
  }
}

export const fetchCourseLessons = async (courseId: string) => {
  try {
    const lessonsSnap = await courseRef
      .doc(courseId)
      .collection('lessons')
      .get()
    return lessonsSnap.docs.map(doc => ({ data: doc.data(), id: doc.id }))
  } catch (e) {
    logger.error(e)
  }
}

export const fetchPublishedCourse = async (courseId: string) => {
  try {
    const courseSnap = await publishedCoursesRef.doc(courseId).get()
    return courseSnap.exists ? { data: { ...courseSnap.data() }, id: courseSnap.id } : null
  } catch (e) {
    logger.error(e)
  }
}

export const fetchStudentMiniCourse = async (courseId: string) => {
  try {
    const courseSnap = await studentsRef
      .doc(authRef.currentUser.uid)
      .collection('miniCourses')
      .doc(courseId)
      .get()

    return courseSnap.exists ? { data: { ...courseSnap.data() }, id: courseSnap.id } : null
  } catch (e) {
    logger.error('fetchStudentMiniCourse', e)
  }
}

export const fetchStudentMiniCourseLessons = async (courseId: string) => {
  try {
    const lessonsSnap = await studentsRef
      .doc(authRef.currentUser.uid)
      .collection('miniCourses')
      .doc(courseId)
      .collection('lessons')
      .get()
    return lessonsSnap.docs.map(doc => ({ data: doc.data(), id: doc.id }))
  } catch (e) {
    logger.error(e)
  }
}

export const fetchMiniCourseLesson = async (courseId: string, lessonId: string) => {
  const lessonSnap = await studentsRef
    .doc(authRef.currentUser.uid)
    .collection('miniCourses')
    .doc(courseId)
    .collection('lessons')
    .doc(lessonId)
    .get()
  if (lessonSnap.exists) {
    return { ...lessonSnap.data(), id: lessonSnap.id }
  }

  return null
}

export const getMiniCourseLesson = async (courseId: string, lessonId: string) => {
  try {
    const course = await fetchStudentMiniCourse(courseId)

    if (course) {
      const lesson = await fetchMiniCourseLesson(courseId, lessonId)

      if (lesson) {
        return {
          ...lesson,
          isAvailable:
            course.data.isFree || course.data.isBought || !course.data.price || lesson.isFree,
        }
      }
    }

    const publishedMiniCourse = await fetchPublishedCourse(courseId)
    const courseLessons = await fetchCourseLessons(courseId)

    await saveStudentMiniCourse(courseId, {
      ...publishedMiniCourse.data,
    })

    const lessons = await saveStudentMiniCourseLessons(courseLessons, courseId)
    const lesson = lessons.find(l => l.id === lessonId)

    return {
      ...lesson,
      isAvailable:
        publishedMiniCourse.data.isFree ||
        publishedMiniCourse.data.isBought ||
        !publishedMiniCourse.data.price ||
        lesson.isFree,
    }
  } catch (e) {
    logger.error('error', e)
  }
}

export const saveMiniCourseAnswers = async ({
  courseId,
  lessonId,
  answers,
  status,
}: {
  courseId: string
  lessonId: string
  answers: ILessonHomeworkAnswers
  status: string
}) => {
  try {
    await studentsRef
      .doc(authRef.currentUser.uid)
      .collection('miniCourses')
      .doc(courseId)
      .collection('lessons')
      .doc(lessonId)
      .update({ answers, status })
  } catch (e) {
    toast('Не удалось сохранить изменения', {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'student-message-failed',
    })
  }
}
export const buyMiniCourse = async (courseId: string) => {
  try {
    await studentsRef
      .doc(authRef.currentUser.uid)
      .collection('miniCourses')
      .doc(courseId)
      .update({ isBought: true })
  } catch (e) {
    logger.error(e)
    toast('Не удалось сохранить изменения', {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'student-message-failed',
    })
  }
}

export const makeLessonUrl = (url: string): string => {
  const arr = url.split('/')

  return arr.join('/')
}

export const startNewLesson = async ({
  lessonId,
  courseId,
  student,
  teacher,
  isChangeLesson = false,
}: {
  lessonId: string
  courseId: string
  student: IStudentProfile
  teacher: IProfile
  isChangeLesson?: boolean
}) => {
  try {
    logger.info({ lessonId, courseId, student, teacher })

    let lesson: IPublishedCourseLesson | ILesson

    const studentCourseRef = await studentsRef
      .doc(`${student.id}/courses/${courseId}/lessons/${lessonId}`)
      .get()

    if (studentCourseRef.exists) {
      lesson = {
        id: studentCourseRef.id,
        ...(studentCourseRef.data() as Omit<ILesson, 'id'>),
      }
    } else {
      const lessonSnap = await activeCoursesRef.doc(`${courseId}/lessons/${lessonId}`).get()
      lesson = {
        id: lessonSnap.id,
        ...(lessonSnap.data() as Omit<IPublishedCourseLesson, 'id'>),
      }
    }

    const batch = firestoreRef.batch()

    batch.update(studentsRef.doc(student.id), {
      currentLessonId: lessonId,
      currentCourseId: courseId,
    })
    batch.update(studentsRef.doc(`${student.id}/courses/${courseId}`), {
      currentLessonId: lessonId,
      lessonIds: firebase.firestore.FieldValue.arrayUnion(lessonId),
    })
    // @ts-ignore
    batch[studentCourseRef.exists ? 'update' : 'set'](
      studentsRef.doc(`${student.id}/courses/${courseId}/lessons/${lessonId}`),
      lesson
    )

    const prevStudentDataSnap = await db.ref(`courses/${student.id}`).get()
    const prevStudentData: {
      [id: string]: { [id: string]: ILessonRealTimeData }
    } = prevStudentDataSnap.val()

    let currentCourseId: string
    let currentLessonId: string
    let prevCourseData: { [id: string]: ILessonRealTimeData }
    let prevLessonData: ILessonRealTimeData

    if (prevStudentData) {
      ;[currentCourseId, prevCourseData] = Object.entries(prevStudentData)?.[0]
    }
    if (prevCourseData) {
      ;[currentLessonId, prevLessonData] = Object.entries(prevCourseData)?.[0]
    }

    if (prevLessonData) {
      if (prevLessonData.dbData) {
        delete prevLessonData.dbData
      }
      if (prevLessonData.pages) {
        delete prevLessonData.pages
      }
      batch.update(
        studentsRef.doc(`${student.id}/courses/${currentCourseId}/lessons/${currentLessonId}`),
        {
          dbData: prevLessonData,
        }
      )
      db.ref(`courses/${student.id}/${currentCourseId}`).remove()
    }

    await batch.commit()
    const url = window.location.pathname.split('/')
    const currentPage = url.length > 0 ? url[url.length - 1] : '0'

    const realTimeData: ILessonRealTimeData = (lesson as ILesson).dbData
      ? { ...(lesson as ILesson).dbData, isStarted: true, currentPage: currentPage.toString() }
      : {
          isStarted: true,
          currentPage: currentPage.toString(),
        }

    realTimeData.teacherId = teacher.id

    db.ref(`courses/${student.id}/${courseId}/${lessonId}`).set(realTimeData)
    if (isChangeLesson) {
      db.ref(`isStartLesson/${student.id}`).update({
        pathname: makeLessonUrl(window.location.pathname),
      })
    } else {
      db.ref(`isStartLesson/${student.id}`).set({
        start: moment.tz(TZ_MSK).format(),
        pathname: makeLessonUrl(window.location.pathname),
      })
    }
  } catch (e) {
    console.error(e)
  }
}

export const writePassedLesson = async (params: {
  student: IStudentProfile
  teacher: IProfile
  classId: string
  isCourse?: boolean
  courseId?: string
}) => {
  try {
    const { student, teacher, classId, isCourse, courseId } = params

    const studentId = student.id
    const teacherId = teacher.id

    const [passedLessons, payments] = await Promise.all([
      getPassedLessonsTeacherByStudent(teacherId, studentId),
      fetchStudentPayments(studentId),
    ])
    const date = firebaseTimestamp.now()

    const batch = firestoreRef.batch()

    let isTrialLesson = student.student
      ? payments.filter(item => item.amount === 0).length === 0
      : payments.reduce((balance, payment) => {
          return balance + payment.package
        }, 0) <= 0

    let type
    if (teacher.isNative) {
      type = 'hispanic'
    } else if (student.rate === 'couple') {
      type = 'family'
    } else {
      type = 'russian'
    }

    const payment: IPayment = {
      amount: 0,
      currency: 'RUB',
      date,
      package: !isTrialLesson ? -1 : 0,
      n: `${teacher.lastName} ${teacher.name}`,
      tid: teacherId,
      type: type,
    }

    if (isCourse) {
      payment.c = classId
      payment.cs = courseId
    } else {
      payment.ls = classId
    }

    if (payments.length > 0) {
      batch.update(firestoreRef.collection(API_PATHS.payments).doc(studentId), {
        data: firebase.firestore.FieldValue.arrayUnion(payment),
      })
    } else {
      batch.set(firestoreRef.collection(API_PATHS.payments).doc(studentId), {
        data: firebase.firestore.FieldValue.arrayUnion(payment),
      })
    }
    const { balanceCount, passedLessonCount } = payments.reduce(
      (prev, payment) => {
        const result = {
          balanceCount: prev.balanceCount + payment.package,
          passedLessonCount: prev.passedLessonCount,
        }
        if (payment.package < 0) {
          result.passedLessonCount++
        }
        return result
      },
      { balanceCount: 0, passedLessonCount: 0 }
    )
    if (!isTrialLesson) {
      const passedLessons = await getPassedLessonsTeacherByStudent(teacher.id, student.id)

      const rateInfo = getTeacherRatesInfo(teacher.rate, passedLessons.length, teacher.rates)
      const rateLesson = getRateLesson(student.rate, rateInfo.rate)

      const lessonData: IPassedLesson = {
        s: PassedLessonStatus.active,
        n: ` ${student.lastName} ${student.name}`,
        d: date,
        r: rateLesson.toString(),
        cr: rateInfo.currency,
      }

      if (isCourse) {
        lessonData.c = classId
        lessonData.cs = courseId
      } else {
        lessonData.ls = classId
      }

      if (passedLessons.length > 0) {
        batch.update(
          firestoreRef
            .collection(API_PATHS.users)
            .doc(teacherId)
            .collection(API_PATHS.passedLessons)
            .doc(studentId),
          {
            lessons: firebase.firestore.FieldValue.arrayUnion(lessonData),
            timeUpdate: date,
          }
        )
      } else {
        batch.set(
          firestoreRef
            .collection(API_PATHS.users)
            .doc(teacherId)
            .collection(API_PATHS.passedLessons)
            .doc(studentId),
          {
            lessons: firebase.firestore.FieldValue.arrayUnion(lessonData),
            timeUpdate: date,
          }
        )
      }

      batch.update(firestoreRef.collection(API_PATHS.students).doc(studentId), {
        passedLessons: firebase.firestore.FieldValue.increment(1),
      })

      let tutor: IProfile | undefined
      if (student.tutorId) {
        tutor = await fetchTeacherProfileByUid(student.tutorId)
      }

      const data = {
        id: `${student.id}${teacher.id}`,
        message: {
          id: teacher.id,
          m: `${teacher.name} ${teacher.lastName}`,
          path: window.location.pathname,
          date: firebaseTimestamp.now().toMillis(),
          type: MessageType.regularLesson,
        },
      }

      await chatAPI.sendMessage(data)
      await chatAPI.addUnreadMessage({
        userId: student.id,
        isTeacher: true,
        addUserId: teacher.id,
      })

      sendMessageCountingLessons({
        dateTime: moment(new Date()).format('DD.MM.YYYY'),
        countLesson: balanceCount,
        studentId: student.id,
        teacherId: teacher.id,
        is16Lesson: getIs16Lesson(passedLessonCount),
        name: `${student.name} ${student.lastName}`,
        countOfLesson: passedLessonCount,
        email: student.email,
        locale: student.lang,
        tutorTelegram: tutor?.telegram || '',
        tutorName: tutor?.name || '',
        isNativeTeacher: teacher.isNative,
        ...(student?.isTgOn && { studentCid: student.notifications?.cid }),
      })
    } else {
      const data = {
        id: `${student.id}${teacher.id}`,
        message: {
          id: teacher.id,
          m: `${teacher.name} ${teacher.lastName}`,
          path: window.location.pathname,
          date: firebaseTimestamp.now().toMillis(),
          type: MessageType.trialLesson,
        },
      }

      await chatAPI.sendMessage(data)
      await chatAPI.addUnreadMessage({
        userId: student.id,
        isTeacher: true,
        addUserId: teacher.id,
      })
      if (isCourse) {
        sendMessageStartTrialCourseLesson({
          name: `${student.name} ${student.lastName}`,
          teacherName: `${teacher.name} ${teacher.lastName}`,
          email: student.email,
          courseId,
          lessonId: classId,
          locale: student.lang,
        })
      } else {
        sendMessageStartTrialLesson({
          name: `${student.name} ${student.lastName}`,
          teacherName: `${teacher.name} ${teacher.lastName}`,
          email: student.email,
          class: classId,
          locale: student.lang,
        })
      }
    }
    await batch.commit()
  } catch (e) {
    logger.error(e)
    toast('Не удалось начать урок', {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'student-message-failed',
    })
    throw e
  }
}

export const changeTeacherCourseNotes = ({
  studentId,
  courseId,
  teacherNotes,
}: ITeacherCourseNotesUpdates) => {
  studentsRef.doc(`${studentId}/courses/${courseId}`).update({ teacherNotes })
}

export const changeLessonNotebookText = ({
  text,
  scrollPosition,
  studentId,
  userId,
}: ILessonNotebookProps) => {
  db.ref(`students/${studentId}/notebookState`).set({
    text,
    userTyping: userId,
    ...(scrollPosition && { scrollPosition }),
  } as ILessonRealTimeDataNotebookState)
}
export const changeLessonVocabularyText = ({
  text,
  scrollPosition,
  studentId,
  userId,
}: ILessonNotebookProps) => {
  db.ref(`students/${studentId}/vocabularyState`).set({
    text,
    userTyping: userId,
    ...(scrollPosition && { scrollPosition }),
  } as ILessonRealTimeDataNotebookState)
}

export const saveSketchData = ({
  lessonId,
  courseId,
  studentId,
  sketchData,
  id,
  currentPage,
}: {
  lessonId: string
  courseId: string
  studentId: string
  sketchData: string
  id: number
  currentPage: string
}) => {
  studentsRef
    .doc(`${studentId}/courses/${courseId}/lessons/${lessonId}/sketches/${currentPage + id}`)
    .set({
      sketchData,
    })
}
export const savePlayerState = ({
  lessonId,
  courseId,
  studentId,
  playerState,
}: {
  lessonId: string
  courseId: string
  studentId: string
  playerState: Partial<IPlayerState>
}) => db.ref(`courses/${studentId}/${courseId}/${lessonId}/playerState`).update(playerState)

export const getMiniCourseProgress = (lessons: any[]) => {
  let allLessonProgress: boolean[] = []
  lessons.forEach(lesson => {
    const lessonProgress = Object.values(lesson?.answers?.progress || {}) as boolean[]
    allLessonProgress = allLessonProgress.concat(lessonProgress)
  })
  const passedLessons = allLessonProgress.filter(progress => progress)

  let progress: number = (passedLessons.length / allLessonProgress.length) * 100
  return progress ? progress.toFixed(1) : 0
}

const getIs16Lesson = (count: number) => {
  return count !== 0 ? ((count + 1) % 16 === 0 ? true : false) : false
}

export const addRecordURL = async (studentId: string, teacherId: string, newRecord: string) => {
  const teachersRecordsSnap = await recordsRef.doc(teacherId).get()
  const studentRecordsSnap = await recordsRef.doc(studentId).get()

  const studentSnap = await studentsRef.doc(studentId).get()
  const student = studentSnap.data()
  const studentName = student.lastName + ' ' + student.name
  console.log(studentId, teacherId, newRecord)
  const now = moment()
  if (!teachersRecordsSnap.exists) {
    recordsRef.doc(teacherId).set({
      isTeacher: true,
      data: [
        {
          date: firebaseTimestamp.now(),
          id: studentId,
          records: [newRecord],
          student: studentName,
        },
      ],
    })
  } else {
    const allTeacherRecords: IRecord[] = teachersRecordsSnap.data()?.data || []
    let recordUpdated = false

    for (let record of allTeacherRecords) {
      const recordDate = moment(record.date.toDate())
      if (Math.abs(recordDate.diff(now, 'minutes')) <= 60) {
        record.records.push(newRecord)
        record.student = studentName
        recordUpdated = true
        break
      }
    }

    if (!recordUpdated) {
      allTeacherRecords.push({
        date: firebase.firestore.Timestamp.now(),
        id: studentId,
        records: [newRecord],
        student: studentName,
      })
    }

    await recordsRef.doc(teacherId).update({
      data: allTeacherRecords,
    })
  }
  if (!studentRecordsSnap.exists) {
    recordsRef.doc(studentId).set({
      isTeacher: false,
      data: [
        {
          date: firebaseTimestamp.now(),
          id: teacherId,
          records: [newRecord],
        },
      ],
    })
  } else {
    const allStudentRecords: IRecord[] = studentRecordsSnap.data()?.data || []
    let recordUpdated = false

    for (let record of allStudentRecords) {
      const recordDate = moment(record.date.toDate())
      if (Math.abs(recordDate.diff(now, 'minutes')) <= 60) {
        record.records.push(newRecord)
        recordUpdated = true
        break
      }
    }

    if (!recordUpdated) {
      allStudentRecords.push({
        date: firebase.firestore.Timestamp.now(),
        id: studentId,
        records: [newRecord],
      })
    }

    await recordsRef.doc(studentId).update({
      data: allStudentRecords,
    })
  }
}
