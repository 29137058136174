import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { components } from 'react-select'
import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'

import { studentsRef, usersRef } from '@configs/firebase'
import { CALENDAR_EVENT_TYPES, ROLES, TZ_MSK } from '@utils/constants'
import { eventTimes, getMonthName } from '@utils/helpers'
import { customFilterStudentOption, getCounts } from '@utils/helperst'

import { IEvent, IProfile, IStudentProfile } from '@models'
import StudentCard from '@components/Student/StudentCard'
import { AppDispatch, RootState } from '@store'
import { setTeacherStudentsAction } from '@store/personal/actions'
import {
  addEvent,
  updateEvent,
  deleteEvent,
  getStudentsInformation,
  updateStudentComments,
  getStudentCalendarEvents,
} from '@servise'

import {
  TeacherButton as Button,
  Checkbox,
  Select,
  CustomOption,
  CustomSelectLabel,
  MainModal,
} from '@components'

import * as Styled from './styles'
import { sendCalendarNotify, sendMessageTrialEvent } from '@servise/webhookAPI'
import { setStudent } from '@store/classroom/actions'
import RectCheckboxIcon from '@components/Icons/RectCheckboxIcon'
import { EventModalDialog } from '@components/Calendar/EventModal/EventModalDialog'
import { toast } from 'react-toastify'
import { calendarUtil } from '@utils/calendarUtil'
const moment = extendMoment(Moment)

type SelectOptionType = {
  value: any
  label: any
}
type PropsType = {
  cancelHandler: () => void
  openCancelModal: () => void
  teacherId: string
  timeZone: string
  isNewEvent: boolean
  argEvent: any
  isManager: boolean
  teacherStudents?: string[] | null
}

export default function(props: PropsType) {
  const [dates, setDates] = useState<SelectOptionType[]>([])
  moment.tz.setDefault(props.timeZone)
  const [times, setTimes] = useState<SelectOptionType[]>(() => eventTimes())
  const [calendarEvent, setCalendarEvent] = useState<IEvent>({
    type: CALENDAR_EVENT_TYPES.ONCE,
    rrule: {
      freq: 2,
      byweekday: [],
      dtstart: '',
      count: 0,
    },
  })
  const [lessonCounts, setLessonCounts] = useState<SelectOptionType[]>(() => {
    return getCounts()
  })

  const [selectedDate, setSelectedDate] = useState('')
  const [selectedTime, setSelectedTime] = useState('')

  const [studentData, setStudentData] = useState(null)

  const [currentDate, setCurrentDate] = useState('')
  const [prevStudentID, setPrevStudentID] = useState('')
  const [comments, setComments] = useState('')
  const [rows, setRows] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const events = useSelector((state: RootState) => state.calendar.events)
  const profile = useSelector((state: RootState) => state.profile.profile as IProfile)
  const allStudents = useSelector((state: RootState) => state.classroom.students)
  const [students, setStudents] = useState<IStudentProfile[]>([])
  const [isInvalidDate, setIsInvalidDate] = useState<boolean>(false)

  const profileTeacher = useSelector((state: RootState) => {
    return state.personal.teachers.find(teacher => teacher.id === props.teacherId)
  })

  useEffect(() => {
    setStudents([...allStudents])
  }, [allStudents])

  const commentRef = React.useRef<HTMLTextAreaElement>(null)
  const dispatch: AppDispatch = useDispatch()
  useEffect(() => {
    if (props.isManager) {
      dispatch(getStudentsInformation(null))
    }
  }, [dispatch, props.isManager])

  useEffect(() => {
    const { isNewEvent, argEvent, teacherId, isManager } = props
    const teacherName = isManager ? profileTeacher.name : `${profile.name} ${profile.lastName}`
    let newEvent: IEvent = { teacherId, teacherName }

    if (isNewEvent) {
      let byweekday = [
        moment(argEvent.startStr.substring(0, 10))
          .locale('en')
          .format('dd'),
      ]
      newEvent.rrule = {
        freq: 2,
        byweekday,
        dtstart: argEvent.startStr,
        count: 1,
      }

      setCalendarEvent(prevCalendarEvent => ({
        ...prevCalendarEvent,
        ...newEvent,
      }))
      setSelectedTime(argEvent.startStr.substring(11, 16))
      setSelectedDate(argEvent.startStr.substring(0, 10))
      setCurrentDate(argEvent.startStr.substring(0, 10))
    }
  }, [events, props, profileTeacher, profile])
  useEffect(() => {
    const { isNewEvent, argEvent, teacherId } = props
    let newEvent: IEvent = { teacherId }
    if (!isNewEvent) {
      newEvent = events.find(item => item.eventID === argEvent.event.extendedProps.eventID)

      if (newEvent && !newEvent.studentId && newEvent.type !== CALENDAR_EVENT_TYPES.BOOKED) {
        let selectedStudent = students.find(item => item.name === newEvent.title)

        setPrevStudentID(selectedStudent.id)
        setComments(selectedStudent && selectedStudent.comments ? selectedStudent.comments : '')
      }
      if (newEvent && newEvent.studentId) {
        let studentData = students.find(item => item.id === newEvent.studentId)
        setComments(studentData && studentData.comments ? studentData.comments : '')
      }

      newEvent = { ...newEvent, teacherId }
      setSelectedTime(moment(argEvent.event.start).format('HH:mm'))
      setSelectedDate(moment(argEvent.event.start).format('YYYY-MM-DD'))
      setCurrentDate(argEvent.event.start)
      setCalendarEvent({ ...newEvent })
      setPrevStudentID(newEvent.studentId)
    }
  }, [events, props, students])

  const handleOnChangeDate = (date: SelectOptionType) => {
    const dtstart = moment(date.value + ' ' + selectedTime).format()
    const currentDate = new Date()
    const dateToCompare = new Date(date.value)

    // Extract year, month, and day components from both dates

    const byweekday = [
      moment(date.value)
        .locale('en')
        .format('dd'),
    ]

    setCalendarEvent({
      ...calendarEvent,
      rrule: { ...calendarEvent.rrule, byweekday, dtstart },
    })

    if (dateToCompare.getTime() > currentDate.getTime()) {
      setSelectedDate(date.value)
    } else {
      setIsInvalidDate(true)
    }
  }

  const handleChangeStudentData = (data: IStudentProfile) => {
    setStudentData({ ...data })
  }

  const handleUpdateComments = () => {
    let newStudents = students.map(item => (item.id === studentData.id ? { ...studentData } : item))
    setStudents(newStudents)
    dispatch(setStudent(studentData))
    setStudentData(null)
  }

  useEffect(() => {
    var firstDay = moment(currentDate).startOf('month')
    var endDay = moment(currentDate).endOf('month')

    var monthRange = moment.range(firstDay, endDay)
    var newDates = []
    for (let mday of monthRange.by('days')) {
      let monthName = getMonthName(mday)

      newDates.push({
        value: moment(mday).format('YYYY-MM-DD'),
        label: `${mday.format('DD')}
        ${monthName}`,
      })
    }
    setDates(newDates)
  }, [currentDate])

  const handleOnChangeTime = (time: SelectOptionType) => {
    const dtstart = moment(selectedDate + ' ' + time.value).format()

    const byweekday = [
      moment(selectedDate)
        .locale('en')
        .format('dd'),
    ]

    setCalendarEvent({
      ...calendarEvent,
      rrule: { ...calendarEvent.rrule, byweekday, dtstart },
    })
    setSelectedTime(time.value)
  }

  const handleOnChangeSkype = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCalendarEvent({ ...calendarEvent, skype: event.target.value })
  }

  const handleCancelLesson = () => {
    dispatch(deleteEvent({ event: calendarEvent })).then(() => {
      const studentData = students.find(item => {
        if (item.id === calendarEvent.studentId) {
          return { ...item, name: `${item.name} ${item.lastName}` }
        }
      })
      const teacherName = props.isManager
        ? profileTeacher.name
        : `${profile.name} ${profile.lastName}`

      const eventMsk = calendarUtil.convertToMSK(calendarEvent.rrule.dtstart)

      const teacherData = props.isManager ? profileTeacher : profile
      if (studentData) {
        if (calendarEvent.type === CALENDAR_EVENT_TYPES.TRIAL) {
          sendMessageTrialEvent({
            name: `${studentData.name} ${studentData.lastName}`,
            teacher: teacherName,
            email: studentData.email,
            date: eventMsk.format('DD.MM.YYYY'),
            time: eventMsk.format('HH:mm'),
            type: 'Cancel',
            locale: studentData.lang,
            teacher_chat_id: teacherData?.notifications?.cid,
            student_chat_id: studentData?.notifications?.cid,
            telegram: teacherData?.telegram,
          })
        } else {
          sendCalendarNotify({
            studentEmail: studentData.email,
            teacherEmail: teacherData.email,
            studentId: calendarEvent.studentId,
            teacherId: calendarEvent.teacherId,
            studentName: studentData.name,
            teacherName: teacherName,
            date: eventMsk.format('DD.MM.YYYY'),
            time: eventMsk.format('HH:mm'),
            type: 'Cancel',
            p: calendarEvent.type === CALENDAR_EVENT_TYPES.ONCE ? 'once' : 'regular',
            locale: studentData.lang,
            teacher_chat_id: teacherData?.notifications?.cid,
            student_chat_id: studentData?.notifications?.cid,
            telegram: teacherData?.telegram,
            ...(studentData?.isTgOn && { studentCid: studentData.notifications?.cid }),
          })
        }
      }

      props.cancelHandler()
    })
  }

  const handleOnChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = event.target.name as CALENDAR_EVENT_TYPES

    const count = type === CALENDAR_EVENT_TYPES.REGULAR ? 2 : 1

    setCalendarEvent(prevCalendarEvent => ({
      ...prevCalendarEvent,
      rrule: { ...prevCalendarEvent.rrule, count },
      type,
    }))
  }

  const handleonChangeStudent = (value: IStudentProfile) => {
    setComments(value && value.comments ? value.comments : '')

    setCalendarEvent(prevCalendarEvent => ({
      ...prevCalendarEvent,
      title: `${value.name} ${value?.lastName ? value.lastName : ''}`,
      studentId: value.id,
    }))
  }

  useEffect(() => {
    onChangeRowsTextarea()
  }, [comments])

  const handleOnChangeCount = (event: SelectOptionType) => {
    const rrule = { ...calendarEvent.rrule, count: event.value }
    setCalendarEvent(prevCalendarEvent => ({
      ...prevCalendarEvent,
      rrule,
    }))
  }

  const handleAddEvent = () => {
    dispatch(addEvent(calendarEvent))
      .then(() => {
        setIsLoading(false)
        props.cancelHandler()
      })
      .catch(e => {})
  }
  const handleOnChaneBooked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCalendarEvent(prevCalendarEvent => ({
      ...prevCalendarEvent,
      rrule: { ...prevCalendarEvent.rrule, count: 1 },
      count: 1,
      type: e?.target?.checked ? CALENDAR_EVENT_TYPES.BOOKED : CALENDAR_EVENT_TYPES.ONCE,
    }))
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    const currentDate = moment().tz(props.timeZone)
    const selectedDateTime = moment(selectedDate + ' ' + selectedTime).tz(props.timeZone)

    if (selectedDateTime.isBefore(currentDate)) {
      setIsLoading(false)
      // Don't proceed with updating the event.
      props.cancelHandler()
      return
    }

    const studentEvents = await getStudentCalendarEvents(calendarEvent.studentId, props.timeZone)

    const isBookedStudent = checkBoockedEvents(studentEvents)
    const isBookedTeacher = checkBoockedEvents(events)

    if (isBookedStudent || isBookedTeacher) {
      setIsLoading(false)
      if (isBookedStudent) {
        toast('Слот у ученика уже занят', {
          autoClose: 2000,
          position: 'bottom-center',
          closeButton: false,
          hideProgressBar: true,
          className: 'student-card-message',
        })
      } else {
        toast('Слот у учителя уже занят', {
          autoClose: 2000,
          position: 'bottom-center',
          closeButton: false,
          hideProgressBar: true,
          className: 'student-card-message',
        })
      }
    } else {
      if (calendarEvent.type === CALENDAR_EVENT_TYPES.BOOKED) {
        handleAddEvent()
      } else {
        if (props.isManager) {
          if (!props.teacherStudents.includes(calendarEvent.studentId)) {
            await usersRef.doc(props.teacherId).update({
              students: [...props.teacherStudents, calendarEvent.studentId],
            })
            dispatch(
              setTeacherStudentsAction({
                students: [...props.teacherStudents, calendarEvent.studentId],
                id: props.teacherId,
              })
            )
            const studentData = students.find(item => item.id === calendarEvent.studentId)
            const teachers = studentData.teachers || []
            if (!teachers.includes(props.teacherId)) {
              await studentsRef.doc(studentData.id).update({
                teachers: [...teachers, props.teacherId],
              })
            }
            if (calendarEvent.type === CALENDAR_EVENT_TYPES.TRIAL) {
              await updateStudentComments({
                uid: calendarEvent.studentId,
                comments,
              })
            }
          }
        }
        const studentData = students.find(item => {
          if (item.id === calendarEvent.studentId) {
            return { ...item, name: `${item.name} ${item.lastName}` }
          }
        })
        const teacherName = props.isManager
          ? profileTeacher.name
          : `${profile.name} ${profile.lastName}`

        const eventMsk = calendarUtil.convertToMSK(calendarEvent.rrule.dtstart)
        const teacherData = props.isManager ? profileTeacher : profile
        if (props.isNewEvent) {
          if (calendarEvent.type === CALENDAR_EVENT_TYPES.TRIAL) {
            sendMessageTrialEvent({
              name: `${studentData.name} ${studentData.lastName}`,
              teacher: teacherName,
              email: studentData.email,
              date: eventMsk.format('DD.MM.YYYY'),
              time: eventMsk.format('HH:mm'),
              type: 'Create',
              locale: studentData.lang,
              teacher_chat_id: teacherData?.notifications?.cid,
              student_chat_id: studentData?.notifications?.cid,
              telegram: teacherData?.telegram,
            })
          } else {
            sendCalendarNotify({
              studentEmail: studentData.email,
              teacherEmail: teacherData.email,
              studentId: calendarEvent.studentId,
              teacherId: calendarEvent.teacherId,
              studentName: studentData.name,
              teacherName: teacherName,
              date: eventMsk.format('DD.MM.YYYY'),
              time: eventMsk.format('HH:mm'),
              type: props.isNewEvent ? 'Create' : 'Change',
              p: calendarEvent.type === CALENDAR_EVENT_TYPES.ONCE ? 'once' : 'regular',
              locale: studentData.lang,
              teacher_chat_id: teacherData?.notifications?.cid,
              student_chat_id: studentData?.notifications?.cid,
              telegram: teacherData?.telegram,
              ...(studentData?.isTgOn && { studentCid: studentData.notifications?.cid }),
            })
          }
          handleAddEvent()
        } else {
          if (calendarEvent.type === CALENDAR_EVENT_TYPES.TRIAL) {
            sendMessageTrialEvent({
              name: `${studentData.name} ${studentData.lastName}`,
              teacher: teacherName,
              email: studentData.email,
              date: moment(eventMsk).format('DD.MM.YYYY'),
              time: moment(eventMsk).format('HH:mm'),
              type: 'Change',
              locale: studentData.lang,
              teacher_chat_id: teacherData?.notifications?.cid,
              student_chat_id: studentData?.notifications?.cid,
              telegram: teacherData?.telegram,
            })
          } else {
            sendCalendarNotify({
              studentEmail: studentData.email,
              teacherEmail: teacherData.email,
              studentId: calendarEvent.studentId,
              teacherId: calendarEvent.teacherId,
              studentName: studentData.name,
              teacherName: teacherName,
              date: eventMsk.format('DD.MM.YYYY'),
              time: eventMsk.format('HH:mm'),
              type: props.isNewEvent ? 'Create' : 'Change',
              p: calendarEvent.type === CALENDAR_EVENT_TYPES.ONCE ? 'once' : 'regular',
              locale: studentData.lang,
              teacher_chat_id: teacherData?.notifications?.cid,
              student_chat_id: studentData?.notifications?.cid,
              telegram: teacherData?.telegram,
              ...(studentData?.isTgOn && { studentCid: studentData.notifications?.cid }),
            })
          }
          dispatch(
            updateEvent(
              {
                ...calendarEvent,
              },
              prevStudentID,
              null
            )
          )
            .then(() => {
              setIsLoading(false)
              props.cancelHandler()
            })
            .catch(() => {
              setIsLoading(false)
              props.cancelHandler()
            })
        }
      }
    }
  }

  const checkBoockedEvents = (events: IEvent[]) => {
    let dtstart = moment(moment(selectedDate).format('YYYY-MM-DD') + ' ' + selectedTime).format(
      'YYYY-MM-DD HH:mm'
    )
    let allEvents = calendarUtil.getAllEvents(events, props.timeZone, false)
    allEvents = allEvents.filter(e => {
      return (
        moment(currentDate).format('YYYY-MM-DD HH:mm') !==
          moment(e.rrule.dtstart).format('YYYY-MM-DD HH:mm') && e.eventID !== calendarEvent.eventID
      )
    })
    const dateTimes = Array.from({ length: calendarEvent?.rrule?.count }, (_, index) => {
      if (index === 0) return dtstart
      dtstart = moment(dtstart)
        .add(7, 'days')
        .format('YYYY-MM-DD HH:mm')
      return dtstart
    })

    const filterDateTimes = dateTimes.filter(dateTime => {
      return allEvents.some(event => {
        const diff = moment(dateTime).diff(moment(event.rrule.dtstart), 'minutes')

        return diff > -60 && diff < 60
      })
    })

    return filterDateTimes.length > 0
  }

  const onChangeRowsTextarea = () => {
    const textarea = commentRef.current
    if (textarea) {
      const textareaLineHeight = 22

      const previousRows = textarea.rows

      textarea.rows = 1

      const currentRows = ~~(textarea.scrollHeight / textareaLineHeight)

      if (currentRows === previousRows) {
        textarea.rows = currentRows
      }

      if (currentRows >= 4) {
        textarea.rows = 4
        textarea.scrollTop = textarea.scrollHeight
      }

      setRows(currentRows < 4 ? currentRows : 4)
    }
  }

  const handleChangeComments = event => {
    setComments(event.target.value)
  }
  const timeSelectValue = useMemo(() => {
    return times.find(item => item.value === selectedTime) || null
  }, [times, selectedTime])

  return (
    <>
      <Styled.ModalContainer>
        <header>
          <Styled.HeaderRow>
            <span>Выбранная дата</span>
            <Select
              components={{ DropdownIndicator }}
              styles={Styled.customSelectStyles(223)}
              options={dates}
              name="dates"
              isSearchable={false}
              value={dates.find(item => item.value === selectedDate)}
              onChange={handleOnChangeDate}
            />
          </Styled.HeaderRow>
          <Styled.HeaderRow>
            <span>Время</span>
            <Select
              components={{ DropdownIndicator }}
              styles={Styled.customSelectStyles(223)}
              options={times}
              name="times"
              isSearchable={false}
              value={timeSelectValue}
              onChange={handleOnChangeTime}
            />
          </Styled.HeaderRow>

          {!props.isManager && props.isNewEvent && (
            <Styled.LessonnBoockedCheckbox
              name="забронировать время для себя"
              onChange={handleOnChaneBooked}
              checked={calendarEvent.type === CALENDAR_EVENT_TYPES.BOOKED}
              checkedIcon={<RectCheckboxIcon checked />}
              icon={<RectCheckboxIcon />}
            />
          )}
        </header>

        {calendarEvent.type !== CALENDAR_EVENT_TYPES.BOOKED && (
          <main>
            <Styled.HeaderRow>
              <span>Ученик</span>

              <Select
                components={{
                  DropdownIndicator: DropdownIndicator,
                  Option: CustomOption,
                }}
                styles={Styled.customSelectStylesStudent(332)}
                options={students}
                getOptionValue={option =>
                  `${option.name || ''} ${option.lastName || ''} ${option.email}`
                }
                getOptionLabel={option =>
                  `${option.name || ''} ${option.lastName || ''} ${option.email}`
                }
                filterOption={customFilterStudentOption}
                placeholder="Выберите ученика"
                email
                formatOptionLabel={data => (
                  <CustomSelectLabel
                    handleOpenStudentCard={() =>
                      calendarEvent.studentId &&
                      setStudentData(students.find(item => item.id === calendarEvent.studentId))
                    }
                    {...data}
                  />
                )}
                value={students.find(item => item.id === calendarEvent.studentId)}
                onChange={handleonChangeStudent}
              />
            </Styled.HeaderRow>
            <Styled.HeaderRow>
              <span>Skype</span>
              <Styled.Input
                name="skype"
                required
                onChange={handleOnChangeSkype}
                value={calendarEvent.skype}
              />
            </Styled.HeaderRow>

            <Checkbox
              type="radio"
              tip="разовое занятие"
              className="event-modal-checkbox"
              name={CALENDAR_EVENT_TYPES.ONCE}
              checked={calendarEvent.type === CALENDAR_EVENT_TYPES.ONCE}
              onChange={handleOnChangeType}
            />
            {props.isManager && profile.role !== ROLES.MANAGER_ASSISTANT && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Checkbox
                  type="radio"
                  name={CALENDAR_EVENT_TYPES.TRIAL}
                  tip="пробный урок"
                  checked={calendarEvent.type === CALENDAR_EVENT_TYPES.TRIAL}
                  className="event-modal-checkbox"
                  onChange={handleOnChangeType}
                />
                {props.isManager &&
                  calendarEvent.studentId &&
                  calendarEvent.type === CALENDAR_EVENT_TYPES.TRIAL && (
                    <div style={{ width: 442, background: '#F3F5F9', borderRadius: 8 }}>
                      <Styled.CommentTextArea
                        placeholder="Добавить информацию об ученике"
                        rows={rows}
                        value={comments}
                        ref={commentRef}
                        onChange={handleChangeComments}
                      />
                    </div>
                  )}
              </div>
            )}

            <Styled.HeaderRow style={{ height: 50 }}>
              <Checkbox
                type="radio"
                name={CALENDAR_EVENT_TYPES.REGULAR}
                tip="регулярное занятие"
                checked={calendarEvent.type === CALENDAR_EVENT_TYPES.REGULAR}
                className="event-modal-checkbox"
                onChange={handleOnChangeType}
              />
              <div
                style={
                  calendarEvent.type === CALENDAR_EVENT_TYPES.REGULAR ? {} : { display: 'none' }
                }
              >
                <Select
                  components={{ DropdownIndicator }}
                  styles={Styled.customSelectStylesCount}
                  options={lessonCounts}
                  isSearchable={false}
                  value={lessonCounts.find(item => item.value === calendarEvent?.rrule?.count)}
                  onChange={handleOnChangeCount}
                />
              </div>
            </Styled.HeaderRow>
          </main>
        )}

        <footer>
          <Button
            whiteTheme
            disabled={
              (calendarEvent.studentId === undefined &&
                students.length > 0 &&
                calendarEvent.type !== CALENDAR_EVENT_TYPES.BOOKED) ||
              !timeSelectValue
            }
            bgWhite
            children="Сохранить"
            loading={isLoading}
            onClick={handleSubmit}
          />
          {!props.isNewEvent ? (
            <button
              className="mt-20 bold-text"
              children="Отменить урок"
              type="button"
              disabled={
                calendarEvent.studentId === undefined &&
                calendarEvent.type !== CALENDAR_EVENT_TYPES.BOOKED
              }
              onClick={
                calendarEvent.type === CALENDAR_EVENT_TYPES.REGULAR
                  ? props.openCancelModal
                  : handleCancelLesson
              }
            />
          ) : (
            <button
              className="mt-20 bold-text"
              children="Отмена"
              type="button"
              onClick={props.cancelHandler}
            />
          )}
        </footer>

        <MainModal
          open={!!studentData}
          children={() => (
            <StudentCard
              studentData={studentData}
              handleClose={handleUpdateComments}
              handleChange={handleChangeStudentData}
            />
          )}
          handleClose={handleUpdateComments}
          maxWidth={'1130px'}
        />
      </Styled.ModalContainer>
      <>
        {isInvalidDate && (
          <MainModal
            open={!!isInvalidDate}
            children={() => (
              <EventModalDialog
                title={'Нельзя поставить урок на уже прошедший день.'}
                text="Понятно"
                visibilityDialog={isInvalidDate}
                onClick={() => {
                  setIsInvalidDate(false)
                }}
              />
            )}
          />
        )}
      </>
    </>
  )
}
const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="14"
        height="8"
        viewBox="0 0 14 8"
        xmlns="http://www.w3.org/2000/svg"
        fill="#C2CFE0"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976315 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976315 13.3166 -0.0976315 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
        />
      </svg>
    </components.DropdownIndicator>
  )
}
